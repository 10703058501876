/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { AccountsType } from "./types";

const initialStateValue: AccountsType = {
  accounts: [],
  detailedAccounts: [],
  accountsLoading: true,
  detailedAccountsLoading: true,
  currency: "NGN",
};

export const accountSlice = createSlice({
  name: "account",
  initialState: { value: initialStateValue },
  reducers: {
    setAccounts: (state, action) => {
      state.value = { ...state.value, ...action.payload };
      localStorage.setItem("account", action.payload.selected);
    },
    setCurrency: (state, action) => {
      state.value.currency = action.payload;
    },

    resetAccounts: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setAccounts, resetAccounts, setCurrency } = accountSlice.actions;

export default accountSlice.reducer;
