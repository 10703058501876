import { ReactComponent as Loader } from "../../assests/icons/loader.svg";

const RootLoading = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <Loader />
    </div>
  );
};

export default RootLoading;
