/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { TransactionPropType } from "./types";

const initialStateValue: TransactionPropType = {
  paginatedTransactions: {
    path: "",
    data: [],
    links: [],
    total: 0,
    per_page: 0,
    current_page: 1,
    from: 0,
    to: 0,
    last_page: 0,
    first_page_url: "",
    prev_page_url: "",
    next_page_url: "",
    last_page_url: "",
  },
  transaction: [],
  transactionLoading: true,
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState: { value: initialStateValue },
  reducers: {
    setTransaction: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetTransaction: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setTransaction, resetTransaction } = transactionSlice.actions;

export default transactionSlice.reducer;
