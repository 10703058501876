import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IRootState } from "../..";
import KYCDone from "./KYCDone/KYCDone";
import KYCForm from "./KYCForm/KYCForm";
import KYCUpload from "./KYCUpload/KYCUpload";

const KYC = () => {
  const navigate = useNavigate();
  const user = useSelector((state: IRootState) => state.user.value);

  const { has_kyc } = user;
  useEffect(() => {
    if (has_kyc) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line
  }, [has_kyc]);

  const [currentPage, setCurrentPage] = useState<"form" | "upload" | "done">(
    "form"
  );

  const [formdata, setFormdata] = useState("");

  const setUpload = (data: any) => {
    setFormdata(data);
    setCurrentPage("upload");
  };
  const setForm = () => {
    setCurrentPage("form");
  };

  const setDone = () => {
    setCurrentPage("done");
  };

  const displayPage = () => {
    switch (currentPage) {
      case "form":
        return <KYCForm setUpload={setUpload} />;
      case "upload":
        return (
          <KYCUpload setDone={setDone} setForm={setForm} formdata={formdata} />
        );
      case "done":
        return <KYCDone setUpload={setUpload} />;
      default:
        <h1>invalid Page</h1>;
    }
  };
  return <div>{displayPage()}</div>;
};

export default KYC;
