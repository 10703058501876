import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FieldValues, SubmitHandler } from "react-hook-form";

import logo from "../../../assests/icons/logoDark.svg";
import back from "../../../assests/icons/back.svg";

import Transfer from "../../../utils/apiServices/transfer";
import notify from "../../../utils/notify";
import { setTransfer, resetTransfer } from "../reducer";
import ResolveEnaira from "./ResolveEnaira";
import NotFound from "../../../components/ui/page/NotFound";
import GetFee from "./GetFee";
import { IRootState } from "../../..";
import SendPage from "./SendPage";
import Pin from "../Remittance/Pin";
const ENaira = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: IRootState) => state.transfer.value);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const onVerify: SubmitHandler<FieldValues> = (formData) => {
    // console.log(formData);
    setLoading(true);
    Transfer.resolve({ alias: formData.alias })
      .then((res) => {
        if (res.status) {
          const { data } = res;
          dispatch(resetTransfer());
          dispatch(
            setTransfer({
              name: data.name,
              alias: formData.alias,
            })
          );
          setName(data.name);
          // console.log(res);
          setLoading(false);
          dispatch(
            setTransfer({
              proceed: true,
            })
          );
        } else {
          setLoading(false);
          notify("error", res.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "check your internet connection");
      });
  };

  // GET FEE
  const onGetFee: SubmitHandler<FieldValues> = (formData) => {
    // console.log(formData);
    setLoading(true);
    Transfer.getFee({ alias: user.alias, amount: formData.amount })
      .then((res) => {
        if (res.status) {
          const { data } = res;
          // console.log(formData);

          dispatch(
            setTransfer({
              amount: parseInt(formData.amount),
              fee: data.fee,
              note: formData.description,
            })
          );
          // console.log(res);
          setLoading(false);
          setCurrentPage("send");
        } else {
          setLoading(false);
          notify("error", res.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "check your internet connection");
      });
  };

  // SEND

  const onSend = () => {
    // setLoading(true);
    dispatch(
      setTransfer({
        alias: user.alias,
        amount: user.amount,
        name: user.name,
        note: user.note,
      })
    );

    setCurrentPage("pin");
  };

  const [currentPage, setCurrentPage] = useState<
    "resolve" | "getfee" | "send" | "pin"
  >("resolve");

  const setPageResolve = () => {
    setCurrentPage("resolve");
  };

  const displayPage = () => {
    switch (currentPage) {
      case "resolve":
        return (
          <ResolveEnaira
            setGetFee={() => setCurrentPage("getfee")}
            proceed={user.proceed}
            loading={loading}
            onVerify={onVerify}
            name={name || ""}
          />
        );
      case "getfee":
        return <GetFee loading={loading} onVerify={onGetFee} />;
      case "send":
        return <SendPage onSend={onSend} loading={loading} />;
      case "pin":
        return <Pin currency="enaira" type="enaira" />;

      default:
        return <NotFound />;
    }
  };
  return (
    <div className="mb-10">
      <div className="flex sticky top-0 z-[5] bg-white justify-between items-center text-black container mx-auto py-5 mb-10 px-4">
        <Link to="/dashboard">
          <img src={logo} alt="" />
        </Link>
        <div
          className="flex justify-start gap-2 items-center text-lightGreen cursor-pointer"
          onClick={() =>
            currentPage === "getfee"
              ? setPageResolve()
              : currentPage === "send"
              ? setCurrentPage("getfee")
              : currentPage === "pin"
              ? setCurrentPage("send")
              : navigate(-1)
          }
        >
          <span>
            <img src={back} alt="" />
          </span>{" "}
          Go back
        </div>
      </div>
      <div className="flex container mx-auto items-center justify-around bg-red">
        <div className=" flex flex-col text-black justify-center px-7 md:px-20 w-full md:w-[80%] lg:w-[55%] xl:w-[45%]">
          {displayPage()}
        </div>
      </div>
    </div>
  );
};

export default ENaira;
