import React from "react";
import Footer from "../../layout/Footer/Footer";
import Navigation from "../../layout/Navigation/Navigation";

const Pricing = () => {
  return (
    <div>
      <Navigation page="PRICING" />
      <div className="pt-20 md:pt-20">Pricing</div>
      <Footer />
    </div>
  );
};

export default Pricing;
