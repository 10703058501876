import { lazy } from "react";

import About from "../../pages/AboutUs/About";
import Blog from "../../pages/Blog/Blog";
import BlogDetails from "../../pages/Blog/BlogDetails";
import Business from "../../pages/Business/Business";
import Card from "../../pages/Card/Card";
import Company from "../../pages/Company/Company";
import ContactUs from "../../pages/ContactUs/ContactUs";
import FAQs from "../../pages/FAQs/FAQs";
import Features from "../../pages/Features/Features";
import ForgotPassword from "../../pages/ForgotPassword/ForgotPassword";
import Help from "../../pages/Help/Help";
import Home from "../../pages/Home/Home";
import HowItWorks from "../../pages/HowItWorks/HowItWorks";
import KYC from "../../pages/KYC/KYC";
import Personal from "../../pages/Personal/Personal";
import Pricing from "../../pages/Pricing/Pricing";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";
import SendFunds from "../../pages/SendFunds/SendFunds";
import Signup from "../../pages/Signup/Signup";
import QuickPayments from "../../pages/QuickPayments/QuickPayments";
import TermsOfUse from "../../pages/TermsOfUse/TermsOfUse";
import Redirect from "../Redirect";

const Login = lazy(() => import("../../pages/Dashboard/Login"));
const ControlPanel = lazy(() => import("../../layout"));

const pagesRoute = [
  {
    path: "/*",
    component: ControlPanel,
    meta: {
      redirectUrl: "/login",
      protectedRoute: true,
      role: "user",
    },
  },
  {
    path: "/",
    component: Home,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/personal",
    component: Personal,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/business",
    component: Business,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/pricing",
    component: Pricing,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/company",
    component: Company,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/card",
    component: Card,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/help",
    component: Help,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/login",
    component: Login,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/signup",
    component: Signup,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/kyc",
    component: KYC,
    meta: {
      redirectUrl: "/login",
      protectedRoute: true,
      role: "user",
    },
  },
  {
    path: "/send/:page",
    component: SendFunds,
    meta: {
      redirectUrl: "/login",
      protectedRoute: true,
      role: "user",
    },
  },
  {
    path: "/payments/:page",
    component: QuickPayments,
    meta: {
      redirectUrl: "/login",
      protectedRoute: true,
      role: "user",
    },
  },
  {
    path: "/forgotpassword",
    component: ForgotPassword,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/features",
    component: Features,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/howitworks",
    component: HowItWorks,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/blog",
    component: Blog,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/blog/:blogId",
    component: BlogDetails,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/about",
    component: About,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/contact",
    component: ContactUs,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/termsofuse",
    component: TermsOfUse,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/privacypolicy",
    component: PrivacyPolicy,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/faqs",
    component: FAQs,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "*",
    component: Redirect,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
];

export default pagesRoute;
