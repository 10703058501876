/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../assests/icons/arrow-down.svg";

import logo from "../../assests/icons/Logo.svg";
import Google from "../../assests/icons/google-play.svg";
import Apple from "../../assests/icons/apple.svg";

const Footer = () => {
  const getCurrentDate = () => {
    const date = new Date();
    const formatter = new Intl.DateTimeFormat(undefined, {
      year: "numeric",
    });
    return formatter.format(date);
  };

  const [productLink, setProductLink] = useState([
    {
      to: "/features",
      open: false,
      text: "Features",
    },
    {
      to: "/howitworks",
      open: false,
      text: "How it Works",
    },
    {
      to: "/blog",
      open: false,
      text: "Blog",
    },
  ]);

  const [companyLink, setCompanyLink] = useState([
    // {
    //   to: "/about",
    //   open: false,
    //   text: "About Us",
    // },
    {
      to: "/contact",
      open: false,
      text: "Contact us",
    },
    {
      to: "/termsofuse",
      open: false,
      text: "Terms of Use",
    },
    {
      to: "/privacypolicy",
      open: false,
      text: "Privacy Policy",
    },
    {
      to: "/faqs",
      open: false,
      text: "FAQs",
    },
  ]);
  const [socialLinks, setSociaLinks] = useState([
    {
      to: "https://twitter.com",
      open: false,
      text: "Twitter",
    },
    {
      to: "https://facebook.com",
      open: false,
      text: "Facebook",
    },
    {
      to: "https://instagram.com",
      open: false,
      text: "Instagram",
    },
    {
      to: "https://linkedin.com",
      open: false,
      text: "LinkedIn",
    },
  ]);
  const [contactLink, setContactLink] = useState([
    {
      to: "tel:+2349062002122",
      open: false,
      text: "+234 906 200 2122",
    },
    {
      to: "mailto:office@vtmoneyafrica.com",
      open: false,
      text: "office@vtmoneyafrica.com",
    },
    // {
    //   to: "mailto:help@vtmoney.com",
    //   open: false,
    //   text: "help@vtmoney.com",
    // },
  ]);

  const [product, setProduct] = useState(false);
  const [company, setCompany] = useState(false);
  const [social, setSocial] = useState(false);
  const [contact, setContact] = useState(false);

  const toggleProduct = () => {
    setProduct(!product);
  };
  const toggleCompany = () => {
    setCompany(!company);
  };
  const toggleSocial = () => {
    setSocial(!social);
  };
  const toggleContact = () => {
    setContact(!contact);
  };

  return (
    <div className="bg-[#002A16]">
      <div className="container mx-0 lg:mx-auto flex flex-col justify-center items-center py-10 md:py-20">
        <div className="font-black text-white text-center text-[23.44px] md:text-4xl px-8 md:px-0 py-3 md:py-4">
          Do more with mobile money wallet
        </div>
        <div className="text-[14px] md:text-lg text-white mb-4 text-center">
          Accept payments, unlock growth capital and manage your business
          withease
        </div>
        <div className="flex flex-col  md:flex-row gap-4 items-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.app.vtmoney"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="rounded-md border-0 bg-lightGreen text-xs md:text-[14.4px] text-white h-11 px-6 flex gap-4 items-center">
              <img src={Google} alt="" />
              <div className="">Download on Google Play</div>
            </div>
          </a>
          <a href="/" target="_blank" rel="noopener noreferrer">
            <div className="rounded-md border-0 bg-[#33473D] text-xs md:text-[14.4px] text-white h-11 px-6 flex gap-4 items-center">
              <img src={Apple} alt="" />
              <div className="">Download on App Store</div>
            </div>
          </a>
        </div>
        <div className="flex flex-col lg:flex-row items-center md:items-start justify-center lg:justify-between w-full px-4 md:px-32 lg:px-20 pt-12 md:pt-28 gap-0 md:gap-2 ">
          <div className="flex flex-col justify-center items-center md:items-start gap-3 md:gap-6 md:w-[50%]">
            <Link to="/home">
              <img src={logo} alt="" />
            </Link>
            <div className="text-[14px] md:text-base lg:text-base text-center md:text-start text-transWhite7 leading-6 md:leading-9">
              Best mobile money platform for you and your business
            </div>
          </div>
          <div className="flex flex-col w-full justify-start items-start md:hidden pt-14 gap-8">
            <div className="flex flex-col w-full">
              <div
                className="flex justify-between items-center"
                onClick={() => toggleProduct()}
              >
                <div className=" text-transWhite font-medium text-base">
                  Product
                </div>
                <Arrow className={`${!product ? "" : "rotate-180"}`} />
              </div>
              <div className={`${product ? "block" : "hidden"}`}>
                {productLink.map((item) => (
                  <div
                    className="text-white font-normal text-base mt-6"
                    key={item.text}
                  >
                    <Link to={item.to}>{item.text}</Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div
                className="flex justify-between items-center"
                onClick={() => toggleCompany()}
              >
                <div className=" text-transWhite font-medium text-base">
                  Company
                </div>
                <Arrow className={`${!company ? "" : "rotate-180"}`} />
              </div>
              <div className={`${company ? "block" : "hidden"}`}>
                {companyLink.map((item) => (
                  <div
                    className="text-white font-normal text-base mt-6"
                    key={item.text}
                  >
                    <Link to={item.to}>{item.text}</Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div
                className="flex justify-between items-center"
                onClick={() => toggleSocial()}
              >
                <div className=" text-transWhite font-medium text-base">
                  Social
                </div>
                <Arrow className={`${!social ? "" : "rotate-180"}`} />
              </div>
              <div className={`${social ? "block" : "hidden"}`}>
                {socialLinks.map((item) => (
                  <div
                    className="text-white font-normal text-base mt-6"
                    key={item.text}
                  >
                    <a target="_blank" rel="noopener noreferrer" href={item.to}>
                      {item.text}
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div
                className="flex justify-between items-center"
                onClick={() => toggleContact()}
              >
                <div className=" text-transWhite font-medium text-base">
                  Contact
                </div>
                <Arrow className={`${!contact ? "" : "rotate-180"}`} />
              </div>
              <div className={`${contact ? "block" : "hidden"}`}>
                {contactLink.map((item) => (
                  <div
                    className="text-white font-normal text-base mt-6"
                    key={item.text}
                  >
                    <a href={item.to}>{item.text}</a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="hidden md:flex flex-col md:flex-row justify-evenly items-start w-full gap-3">
            <div className="flex flex-col justify-center items-start">
              <div className="font-medium text-transWhite text-[22px] ">
                PRODUCT
              </div>
              <div>
                {productLink.map((item) => (
                  <div className="text-white mt-6" key={item.to}>
                    <Link to={item.to}>{item.text}</Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col justify-center items-start">
              <div className="font-medium text-transWhite text-[22px] ">
                COMPANY
              </div>
              <div>
                {companyLink.map((item) => (
                  <div className="text-white mt-6" key={item.to}>
                    <Link to={item.to}>{item.text}</Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col justify-center items-start">
              <div className="font-medium text-transWhite text-[22px] ">
                SOCIAL
              </div>
              <div>
                {socialLinks.map((item) => (
                  <div className="text-white mt-6" key={item.to}>
                    <a href={item.to} target="_blank" rel="noopener noreferrer">
                      {item.text}
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col justify-center items-start">
              <div className="font-medium text-transWhite text-[22px] ">
                Contact
              </div>
              <div>
                {contactLink.map((item) => (
                  <div className="text-white mt-6" key={item.to}>
                    <a href={item.to}>{item.text}</a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="font-medium text-white text-sm md:text-base mt-20">
          VT Money | All Rights Reserved &copy; {getCurrentDate()}
        </div>
      </div>
    </div>
  );
};

export default Footer;
