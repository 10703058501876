import React from "react";
import Footer from "../../layout/Footer/Footer";
import Navigation from "../../layout/Navigation/Navigation";

const HowItWorks = () => {
  return (
    <div>
      <Navigation page="How it Works" />
      <div className="pt-0 md:pt-20">HowItWorks</div>
      <Footer />
    </div>
  );
};

export default HowItWorks;
