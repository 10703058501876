/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import vector from "../../../assests/images/contact-vector.png";
import Img1 from "../../../assests/images/signup1.png";
import Img2 from "../../../assests/images/signup2.png";
import vector2 from "../../../assests/images/vecGreen.png";
import logo from "../../../assests/icons/logoDark.svg";

const SignupCompleted = () => {
  const navigate = useNavigate();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          navigate("/login");
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [seconds]);

  return (
    <div className="h-screen flex flex-col justify-center items-center md:block relative">
      <div className="flex fixed md:sticky top-0 z-[5] bg-white justify-between items-center text-black container mx-auto py-5 md:mb-10 px-4">
        <Link to="/">
          <img src={logo} alt="" className="w-28 md:w-32" />
        </Link>
        <Link className="font-medium" to="/login">
          Log in{" "}
        </Link>
      </div>
      <div className="flex container mx-auto items-center justify-around">
        <div className=" hidden lg:block self-start mt-0 md:mt-28 relative">
          <img className="" src={Img1} alt="" />
          <img
            className="-z-[1] absolute w-[70%] -top-10 -left-8"
            src={vector}
            alt=""
          />
        </div>
        <div className=" text-black px-7 md:px-20 w-full md:w-[80%] lg:w-[50%]">
          <div className="flex flex-col justify-center items-center mx-auto container">
            <div className="text-[22.5px] font-black">
              Registration completed
            </div>

            <div className="text-base pt-2 text-center">
              You have successfully completed your registration with VT Money.
              Now enjoy all the perks of money transfer
            </div>
          </div>
          <button
            className="flex justify-center items-center mt-10 rounded-md border-0 bg-lightGreen font-black text-white h-12 px-4 w-full"
            // onClick={() => handleSubmit()}
          >
            Redirecting To Dashboard In {seconds}s
          </button>
        </div>
        <div className=" -bottom-16 right-3 lg:relative self-end relative">
          <div className="hidden lg:block self-start mt-28 relative">
            <img className="" src={Img2} alt="" />
            <img
              className="-z-[1] absolute w-[70%] -bottom-20 -right-12"
              src={vector2}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupCompleted;
