import React from "react";
import Select from "react-select";
import GHS from "./GHS";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { useSelector } from "react-redux";
import { IRootState } from "../../..";

type ResolveMomoPropType = {
  accountName: string;
  mobileNumber: any;
  setMobileNumber: (data: any) => void;
  setAccountName: (e: any) => void;
  setGetFee: SubmitHandler<FieldValues>;
  proceed: boolean;
  loading: boolean;
  currency: string;
  setCurrency: (e: string) => void;
  location: number;
  setLocation: (e: number) => void;
};

const ResolveMomo = ({
  accountName,
  currency,
  loading,
  location,
  mobileNumber,
  proceed,
  setAccountName,
  setCurrency,
  setGetFee,
  setLocation,
  setMobileNumber,
}: ResolveMomoPropType) => {
  const transfer = useSelector((state: IRootState) => state.transfer.value);
  return (
    <div>
      <div className="">
        <label
          className="text-sm md:text-[17px] font-medium"
          htmlFor="currency"
        >
          Select Currency
        </label>
        <div className="FormSelectMain">
          <Select
            defaultInputValue={currency}
            onChange={(e: any) => setCurrency(e.value)}
            options={[{ value: "ghs", label: "GHS" }]}
          />
        </div>
        {currency === "ghs" ? (
          <GHS
            accountName={accountName}
            loading={loading}
            mobileNumber={mobileNumber}
            onVerify={() => {}}
            proceed={proceed}
            setAccountName={setAccountName}
            setGetFee={setGetFee}
            setMobileNumber={setMobileNumber}
          />
        ) : (
          <div className="">none</div>
        )}
      </div>
    </div>
  );
};

export default ResolveMomo;
