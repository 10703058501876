/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import FormButton from "../../../components/FormComponents/FormButton/FormButton";
import logo from "../../../assests/icons/logoDark.svg";
import back from "../../../assests/icons/back.svg";

// Api Service
import Authentication from "../../../utils/apiServices/authentication";
import notify from "../../../utils/notify";
import FormSelect from "../../../components/FormComponents/FormSelect/FormSelect";
import FormInput from "../../../components/FormComponents/FormInput/FormInput";
import { setUser } from "../../../features/profile/reducer";
import { setBank } from "../../../features/dashbard/reducer";

type KYCUploadPropType = {
  setDone: () => void;
  setForm: () => void;
  formdata: any;
};

const KYCUpload = ({ setDone, setForm, formdata }: KYCUploadPropType) => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [documentLoading, setDocumentLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    Authentication.getDocumentTypes()
      .then((res) => {
        if (res.status) {
          setDocuments(
            res.data.map((entry: { name: string; id: number }) => ({
              label: entry.name,
              id: entry.id,
            }))
          );
          setDocumentLoading(false);
          // console.log("docs is", docs);
        } else {
          setDocumentLoading(false);
        }
      })
      .catch(() => {
        setDocumentLoading(false);
      });
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);

  const { register, control, formState, handleSubmit } = useForm();

  const onSignUp: SubmitHandler<FieldValues> = (formData) => {
    setLoading(true);
    Authentication.completeKYC({
      firstname: formdata.firstname,
      middlename: formdata.middlename,
      lastname: formdata.lastname,
      dob: formdata.dob,
      bvn: formdata.bvn,
      city: formdata.city,
      country: formdata.country.label,
      address: formdata.address,
      state: formdata.state,
      postal_code: formdata.poatalcode,
      business_name: formdata.business_name,
      registration_number: formdata.registration_number,
      tin: formdata.tin,
      document_number: formData.document_number,
      document_type_id: formData.documents.id,
    })
      .then((res) => {
        if (res.status) {
          const { data } = res;
          // console.log(data);
          // Dispatch Login
          dispatch(
            setUser({
              id: data.user.id,
              uuid: data.user.uuid,
              firstname: data.user.firstname,
              middlename: data.user.middlename,
              lastname: data.user.lastname,
              name: data.user.name,
              email: data.user.email,
              phone: data.user.phone,
              dob: data.user.dob,
              country_code: data.user.country_code,
              country: data.user.country,
              address: data.user.address,
              postal_code: data.user.poatalcode,
              state: data.user.state,
              city: data.user.city,
              banks: data.user.banks,
              has_kyc: data.user.has_kyc,
              // access_token: data.token.access_token,
              // loggedIn: true,
              // remember_me: true,
            })
          );
          setLoading(false);
          notify("success", res.message);
          // setDone();
          dispatch(setBank({ popup: true }));
        } else {
          setLoading(false);
          notify("error", res.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "An error occured. Check your internet");
      });
  };

  return (
    <div className="mb-10">
      <div className="flex sticky top-0 z-[5] bg-white justify-between items-center text-black container mx-auto py-5 mb-10 px-4">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
        <div
          className="flex justify-start gap-2 items-center text-lightGreen cursor-pointer"
          onClick={() => setForm()}
        >
          <span>
            <img src={back} alt="" />
          </span>{" "}
          Go back
        </div>
      </div>
      <div className="flex container mx-auto items-center justify-around">
        <div className=" flex flex-col text-black justify-center px-7 md:px-20 w-full md:w-[80%] lg:w-[55%] xl:w-[45%]">
          <div className=" mx-auto container">
            <div className="text-[22.5px] font-black">
              Document Verification
            </div>
            <div className="flex text-base pt-2">
              Kindly upload a verified government issed ID card (Ex: NIN,
              International passport or Driver's license).
            </div>
            <form onSubmit={handleSubmit(onSignUp)}>
              <FormSelect
                placeholder={documentLoading ? "loading..." : "Select One"}
                label="Documents"
                formState={formState}
                rules={{ required: "Select Document" }}
                control={control}
                name="documents"
                options={documents}
              />
              <FormInput
                label="Document Number"
                type="text"
                register={register}
                name="document_number"
                rules={{ required: "Enter your document number" }}
                formState={formState}
                placeholder="Document number"
              />
              <FormButton
                label="Proceed"
                type="big"
                color="green"
                // error={formState.errors.terms_and_conditions}
                loading={loading}
              />
            </form>
            {/* {documents.map((doc) => (
              <h1>{doc.id}</h1>
            ))} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KYCUpload;
