import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import FormInput from "../../../components/FormComponents/FormInput/FormInput";
import FormButton from "../../../components/FormComponents/FormButton/FormButton";
import { useSelector } from "react-redux";
import { IRootState } from "../../..";

type ResolveEnairaPropType = {
  onVerify: SubmitHandler<FieldValues>;
  loading: boolean;
};

const GetRemittanceFee = ({ loading, onVerify }: ResolveEnairaPropType) => {
  const user = useSelector((state: IRootState) => state.transfer.value);
  const { register, formState, handleSubmit } = useForm();
  return (
    <form onSubmit={handleSubmit(onVerify)}>
      <div className="mt-8">
        <label className="text-[17px] font-medium" htmlFor="alias">
          Account Number
        </label>
        <div className="relative">
          <input
            className="h-12 w-full rounded-[4px] border border-gray-400 outline-none py-1 mt-2 pr-[40px] pl-5"
            type="text"
            value={user ? user.account_number : ""}
            disabled
          />
        </div>
      </div>
      <div className="mt-8">
        <label className="text-[17px] font-medium" htmlFor="alias">
          Account Name
        </label>
        <div className="relative">
          <input
            className="h-12 w-full rounded-[4px] border border-gray-400 outline-none py-1 mt-2 pr-[40px] pl-5"
            type="text"
            value={user ? user.name : ""}
            disabled
          />
        </div>
      </div>
      <FormInput
        label="Amount"
        name="amount"
        register={register}
        formState={formState}
        rules={{
          required: "Enter Amount",
          min: {
            value: 100,
            message: "Ammount must be at least 100 NGN",
          },
        }}
        type="number"
      />
      <FormInput
        label="Description"
        name="description"
        register={register}
        formState={formState}
        rules={{
          required: "Enter Description",
        }}
        type="text"
      />

      <FormButton label="Proceed" type="big" color="green" loading={loading} />
    </form>
  );
};

export default GetRemittanceFee;
