import { useNavigate } from "react-router-dom";
import { ReactComponent as Arrow } from "../../assests/icons/arrow.svg";
import Naira from "../../assests/images/naira.png";
import pos from "../../assests/images/vt-pos.png";
import a from "../../assests/images/a.png";
import b from "../../assests/images/b.png";
import c from "../../assests/images/c.png";
import d from "../../assests/images/d.png";
import e from "../../assests/images/e.png";
import line from "../../assests/images/home-v.png";
import Mobile from "../../assests/images/mobile.png";
import greenDot from "../../assests/images/greenDot.png";
import yellowDot from "../../assests/images/yellowDot.png";
import Hero from "../.././assests/images/hero-business.png";
import GreenCard from "../../assests/images/h-vtcard-green.png";
import WhiteCard from "../../assests/images/h-vtcard-white.png";

import fast from "../../assests/icons/faster.svg";
import quote from "../../assests/icons/quote.svg";
import success from "../../assests/icons/success.svg";
import charge from "../../assests/icons/charge.svg";
import grow from "../../assests/icons/grow.svg";

import Send from "../../assests/icons/h-send.svg";
import Receive from "../../assests/icons/h-receive.svg";

import Navigation from "../../layout/Navigation/Navigation";
import Footer from "../../layout/Footer/Footer";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="home">
      <Navigation page="HOME" />
      <div className="flex flex-col  justify-center bg-darkGreen pt-[70px] md:pt-20 overflow-y-auto">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row relative justify-between items-center gap-7 py-12 px-4 xl:px-10 2xl:px-20 bg-hero bg-cover">
            <div className="flex flex-col justify-center items-start relative gap-6 md:w-[50%]">
              <h1 className="md:leading-[62px] text-3xl md:text-[50.95px] text-white font-black">
                Mobile Money for <br /> your
                <span className="text-lightGreen"> Business.</span>
              </h1>
              <h5 className=" text-white leading-9 text-sm md:text-base font-medium">
                From effortless money management to exciting bills payment and
                card services, opening an account has never been easier. With
                just a few clicks, you can enjoy a range of benefits and start
                taking control of your finances today.
              </h5>
              <div className="flex w-full">
                <input
                  className="border-none outline-none text-white bg-trans text-[14.4px] px-4 md:px-0 lg:px-4 w-[65%]"
                  type="tel"
                  placeholder="Phone number"
                  // onChange={() => navigate("/signup")}
                />
                <button
                  className="rounded-r-md border-0 bg-lightGreen font-black text-[14.4px] text-white h-12 md:px-6 w-[35%]"
                  onClick={() => navigate("/signup")}
                >
                  Create wallet
                </button>
              </div>
            </div>
            <div className="relative flex flex-col">
              <img
                src={Hero}
                alt=""
                className="md:rounded-2xl w-[400px] h-[400px] object-cover"
              />
              <img
                className="w-[151px]  hidden md:block  absolute -top-12 -left-16 animate-pulse"
                src={Naira}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="flex flex-col  bg-white text-black">
        <div className="container mx-auto">
          <div className="flex flex-col justify-center items-center py-14 md:py-20 px-5 xl:px-10 2xl:px-20 gap-8 md:gap-12">
            <div className="font-black text-xl md:text-[35px] md:text-center">
              Stay ahead of the game and manage your finances with ease.
            </div>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex flex-col items-start rounded-xl p-4 pb-3 gap-3 bg-[#FAFAFA] md:w-1/3 ">
                <img src={fast} alt="" />
                <div className="font-black text-base md:text-lg mt-2">
                  EXPENSE MANAGEMENT
                </div>
                <div className="text-[15px] font-normal">
                  Effortlessly manage your global spending and quickly close
                  your books in real-time with our advanced solution.
                </div>
              </div>
              <div className="flex flex-col items-start rounded-xl p-4 gap-3 bg-[#FAFAFA] md:w-1/3">
                <img src={charge} alt="" />
                <div className="font-black text-base md:text-lg mt-2">
                  BILLS PAYMENT
                </div>
                <div className="text-[15px] font-normal">
                  From Meter top-ups, cable subscription , data bundle and
                  airtime purchase are ultimately convenience on VTMoney
                </div>
              </div>
              <div className="flex flex-col items-start rounded-xl p-4 gap-3 bg-[#FAFAFA] md:w-1/3">
                <img src={grow} alt="" />
                <div className="font-black text-base md:text-lg mt-2">
                  GLOBAL DEBIT CARD
                </div>
                <div className="text-[15px] font-normal">
                  At last, a card that companies can rely on. Our card offers
                  both increased spending power and intelligent controls,
                  allowing for effortless expense management and reporting.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* # */}
      <div className=" bg-darkGreen text-white">
        <div className="container mx-auto">
          <div className="flex relative flex-col justify-center items-center md:gap-10 pt-5 pb-24 px-5 xl:px-10 2xl:px-20">
            <img
              className="hidden md:flex absolute md:top-[59%] lg:top-[57%] h-[18%] w-[60%] lg:h-[23%] lg:w-[60%]"
              src={line}
              alt=""
            />
            <h1 className="text-[#FAE650] font-black text-2xl md:text-4xl pt-10 md:pt-20">
              Everything to suit your business
            </h1>
            {/* 1 */}
            <div className="flex flex-col md:flex-row items-center justify-between px-5 md:px-4  md:mt-10 mb-10 md:mb-36 gap-3">
              <div className="flex flex-col md:w-1/2 md:pr-28">
                <h3 className="mt-8 text-xl md:text-2xl font-black">
                  Accept Instant Payments
                </h3>
                <h5 className="mt-3 leading-7">
                  Choose how your customers will pay you. Accept one-time and
                  recurring payments instantly by online, card, POS, and bank
                  transfers. Receive payment in real time.
                </h5>
              </div>
              <div className="justify-end flex md:w-1/2 ">
                <div className="flex w-fit relative">
                  <div className="absolute top-44 md:top-10 -left-8 md:-left-32 flex flex-col justify-center items-center bg-white rounded-xl px-4 py-4 z-[3]">
                    <img src={success} alt="" />
                    <div className="text-black font-black text-sm">
                      Payment Received
                    </div>
                    <div className="text-xs text-[#757575]">
                      A sum of <span className="text-lightGreen">N2,000</span>{" "}
                      has been deposited to your acount
                    </div>
                  </div>
                  <img
                    src={a}
                    alt=""
                    className="rounded-xl md:w-[400px] md:h-[400px] z-[2]"
                  />
                  <div className="hidden lg:block h-[100px] w-[300px] bg-[#D6C230] absolute -top-8 -right-9 rounded-xl "></div>
                  <div className="hidden lg:block h-[100px] w-[300px] bg-lightGreen absolute -bottom-8 -left-8 rounded-xl "></div>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className="flex flex-col-reverse md:flex-row  items-center justify-between gap-3 mb-10 md:mb-36 ">
              <div className=" flex md:w-1/2 ">
                <div className="w-fit relative flex">
                  <div className="absolute top-[60%] md:top-[30%] -right-4 md:-right-20 flex flex-col justify-center items-center bg-white rounded-xl  z-[3] ">
                    <div className="text-xs text-lightGreen bg-[#DFF8EC] w-full text-center rounded-t-xl p-2">
                      You are eligible to get a loan of 10M
                    </div>
                    <div className="flex flex-col justify-center items-center px-4 py-4 gap-2">
                      <img src={success} alt="" />
                      <div className="text-black font-black text-sm">
                        Loan Request Succesful
                      </div>
                      <div className="text-xs text-[#757575]">
                        You have received a loan of{" "}
                        <span className="text-black font-black">10M</span>{" "}
                        congratulations
                      </div>
                    </div>
                  </div>
                  <img
                    src={b}
                    alt=""
                    className="rounded-xl md:w-[400px] md:h-[400px] z-[2]"
                  />
                  <img
                    alt=""
                    src={yellowDot}
                    className="hidden md:block absolute -top-8 -right-8 rounded-xl "
                  />

                  <img
                    src={greenDot}
                    alt=""
                    className="hidden md:block absolute -bottom-8 -left-8 rounded-xl "
                  />
                </div>
              </div>
              <div className="flex flex-col md:w-1/2 md:pl-28  justify-end">
                <h3 className="mt-8 text-xl md:text-2xl font-black">
                  Open a free bank account in minutes.
                </h3>
                <h5 className="mt-3 leading-7">
                  Say goodbye to branch visits and paperwork. With our
                  streamlined account opening process, you can create an account
                  in just a few minutes, all from the convenience of your phone.
                </h5>
              </div>
            </div>
            {/* 3 */}
            <div className="flex flex-col md:flex-row w-full items-center justify-between mb-10 gap-3">
              <div className="flex flex-col md:w-1/2 md:pr-28">
                <h3 className="mt-8 text-xl md:text-2xl font-black">
                  Send and receive money globally to friends for free with
                  VTMoney.
                </h3>
                <h5 className="mt-3 leading-7">
                  With no hidden fees and the ability to transfer funds in 20+
                  currencies, you'll enjoy instant and free transfers to other
                  VTMoney users, no matter where you are in the world.
                </h5>
              </div>
              <div className=" justify-end flex md:w-1/2 ">
                <img
                  src={c}
                  alt=""
                  className="md:w-[400px] md:h-[400px] z-[2]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="bg-[#F9F9F9]">
        <div className="container mx-auto px-5 lg:px-56 flex flex-col justify-center items-center pb-10">
          <h1 className="text-[#1C1C1C] font-black text-start text-2xl md:text-4xl pt-10 md:pt-20 pb-12">
            Business solutions to scale your business
          </h1>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col-reverse md:flex-row justify-between items-center text-white rounded-xl md:rounded-3xl bg-pattern bg-cover bg-repeat px-5 md:px-10">
              <div className="md:w-[30%] pt-5">
                <img className="w-[300px]" src={pos} alt="" />
              </div>
              <div className="flex flex-col  gap-y-4 pr-0 md:pr-20 md:w-[65%]">
                <h3 className="mt-8 text-xl md:text-2xl font-black">
                  POS Terminal
                </h3>
                <h5 className="mt-3 text-sm leading-7">
                  Accept payment from your clients with our POS terminals which
                  are secure and dependable for all your card transactions.
                </h5>
                <h5 className="flex items-center gap-3 mt-3 text-sm font-black text-lightGreen cursor-pointer leading-7">
                  Get POS Terminal
                  <span>
                    <Arrow />
                  </span>
                </h5>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-5">
              {/*  */}
              <div className="flex rounded-3xl relative bg-white px-5 md:px-10 md:w-[50%]">
                <div className="flex flex-col gap-y-4">
                  <h3 className="mt-8 text-xl md:text-2xl font-black">
                    Mobile Money Wallet
                  </h3>
                  <h5 className="mt-3 text-sm leading-7">
                    Create VTMoney mobile money wallet to manage every aspect of
                    your business and get easy access to your finances on-the-go
                  </h5>
                  <h5
                    className="flex items-center gap-3 mt-3 text-sm font-black text-lightGreen cursor-pointer leading-7"
                    onClick={() => navigate("/signup")}
                  >
                    Create mobile wallet account
                    <span>
                      <Arrow />
                    </span>
                  </h5>
                  <div className="relative mt-6">
                    <div className="flex  absolute justify-center items-center rounded-lg gap-2 -top-8 -right-9 md:-right-8 shadow-sm bg-[#fafafa] text-xs z-[1] p-2">
                      <img src={Send} alt="" />
                      <div className="text-black">
                        You just sent{" "}
                        <span className="text-lightGreen">$620</span> to Dammy
                      </div>
                    </div>
                    <img className="" src={Mobile} alt="" />
                    <div className="flex  absolute justify-center items-center rounded-lg gap-2 top-[45%] -left-9 md:-left-8 shadow-sm bg-[#fafafa] text-xs z-[1] p-2">
                      <img src={Receive} alt="" />
                      <div className="text-black">
                        <span className="text-lightGreen">$20,000</span>{" "}
                        deposited in your account
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="flex rounded-3xl relative bg-[#F5F5F5] px-5 md:px-10 md:w-[50%]">
                <div className="flex flex-col  gap-y-4">
                  <h3 className="mt-8 text-xl md:text-2xl font-black">
                    Debit Card
                  </h3>
                  <h5 className="mt-3 text-sm leading-7">
                    Bank without limits with our debit cards that allow for more
                    secure payments from your business accounts anywhere you
                    are.
                  </h5>
                  <h5
                    className="flex items-center gap-3 mt-3 text-sm font-black text-lightGreen cursor-pointer leading-7"
                    onClick={() => navigate("/signup")}
                  >
                    Get your debit card now
                    <span>
                      <Arrow />
                    </span>
                  </h5>
                  <div className="relative mt-5 z-[1]">
                    <img
                      className="absolute bottom-0 right-2 -z-[1]"
                      src={WhiteCard}
                      alt=""
                    />
                    <img className="" src={GreenCard} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      {/* <div className="bg-white">
        <div className="container mx-auto flex flex-col justify-center items-center">
          <h1 className="text-[#1C1C1C] font-black text-2xl px-5 md:text-4xl pt-10 md:pt-20 pb-10 text-center">
            Obtain these local account information
          </h1>
          <div className="hidden md:flex justify-center items-center text-black text-sm py-16 px-3">
            <div className="flex flex-col justify-center items-start gap-4 border-r-[1px] border-[#E0E0E0] p-4">
              <div className="font-medium text-2xl">🇳🇬 Nigerian Naira</div>
              <div className="font-normal">
                UK sort code, Account number, and IBAN
              </div>
            </div>
            <div className="flex flex-col justify-center items-start gap-4 border-r-[1px] border-[#E0E0E0] p-4">
              <div className="font-medium text-2xl">🇪🇺 Euro</div>
              <div className="font-normal">Bank code (SWIFT/BIC) and IBAN</div>
            </div>
            <div className="flex flex-col justify-center items-start gap-4 border-r-[1px] border-[#E0E0E0] p-4">
              <div className="font-medium text-2xl">🇦🇸 US Dollar</div>
              <div className="font-normal">
                Routing number and Account number
              </div>
            </div>
            <div className="flex flex-col justify-center items-start gap-4 border-r-[1px] border-[#E0E0E0] p-4">
              <div className="font-medium text-2xl">🇮🇴 British Pound</div>
              <div className="font-normal">
                UK sort code, Account number, and IBAN
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/*  */}
      <div className="bg-white">
        <div className="container mx-auto">
          <div className="flex flex-col justify-center items-center bg-darkGreen md:bg-pattern rounded-2xl px-5 md:px-10 2xl:px-20 py-10 gap-3">
            <h1 className="text-[#FAE650] font-black text-2xl md:text-4xl">
              Trusted by over 10000 Businesses
            </h1>
            <h3 className="text-transWhite7 text-lg">
              See what top businesses have to say about using VTMoney
            </h3>
            <div className="flex flex-col md:flex-row justify-center items-center gap-12 md:px-20 mt-10">
              <img
                className="md:rounded-2xl w-full md:w-[400px] md:h-[400px]"
                src={d}
                alt=""
              />
              <div className="flex flex-col justify-start items-start text-white gap-3">
                <img src={quote} alt="" />
                <h3 className=" text-sm md:text-lg font-normal md:leading-8">
                  You often need loans to service big events in the events
                  industry, especially if you are a small business trying to
                  break in. I was able to get an instant loan from my VTMoney
                  app without any collateral or paperwork.
                </h3>
                <h2 className="text-base md:text-xl font-black">
                  Abisola Adeboye
                </h2>
                <h3 className="text-transWhite7 text-base md:text-lg">
                  CEO Bis' company
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="bg-white">
        <div className="container mx-auto mt-12">
          <div className="flex md:flex-row flex-col-reverse justify-center items-center gap-4 xl:px-10 2xl:px-20 ">
            <div className="flex flex-col gap-4 md:w-1/2 px-4">
              <h1 className="text-back font-black text-base md:text-2xl">
                Our support team is your team
              </h1>
              <h2 className=" text-sm md:text-xl font-normal md:leading-8">
                Contact us at support@vtmoney.com if you need help with any
                business-related questions. Your inquiries can be answered at
                any time by our support staff.
              </h2>
              <button
                className="rounded-md border-0 bg-lightGreen font-black text-[14.4px] text-white h-12 px-6 w-fit hover:scale-105"
                onClick={() => navigate("/contact")}
              >
                Contact support
              </button>
            </div>
            <div className="md:w-1/2 flex justify-end">
              <img className="md:w-[500px] md:h-[400px]" src={e} alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
