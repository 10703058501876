import {
  CompleteKYCParameters,
  CompleteSignupParameters,
  LoginParameters,
  ResendOtpParameters,
  SignupInitializeParameters,
  SignupVerifyParameters,
} from "./types";

import baseURL from "../baseUrl";
import getAccessToken from "../../accessToken";

class Authentication {
  async signupInitialize(data: SignupInitializeParameters) {
    const response = await fetch(`${baseURL}/auth/signup/initialize`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-VTMoney-Client": "web-user",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async signupVerify(data: SignupVerifyParameters) {
    const response = await fetch(`${baseURL}/auth/signup/verify`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-VTMoney-Client": "web-user",
      },
      body: JSON.stringify(data),
    });

    return response.json();
  }

  async signupComplete(data: CompleteSignupParameters) {
    const response = await fetch(
      `${baseURL}/auth/signup/${data.uuid}/complete`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "X-VTMoney-Client": "web-user",
        },
        body: JSON.stringify(data),
      }
    );
    // console.log(data);

    return response.json();
  }

  async resendOTP(data: ResendOtpParameters) {
    const response = await fetch(`${baseURL}/auth/signup/resend`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-VTMoney-Client": "web-user",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async login(data: LoginParameters) {
    const response = await fetch(`${baseURL}/auth/login`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-VTMoney-Client": "web-user",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async logout() {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/auth/logout`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.json();
  }

  // KYC
  async getDocumentTypes() {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/kyc/document-types`, {
      method: "get",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-VTMoney-Client": "web-user",
      },
    });

    return response.json();
  }

  async completeKYC(data: CompleteKYCParameters) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/kyc/complete`, {
      method: "post",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "X-VTMoney-Client": "web-user",
      },
      body: JSON.stringify(data),
    });
    // console.log(data);
    return response.json();
  }
}
const instance = new Authentication();

export default instance;
