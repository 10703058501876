import { useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../components/FormComponents/FormInput/FormInput";
import FormSelect from "../../../components/FormComponents/FormSelect/FormSelect";
import FormButton from "../../../components/FormComponents/FormButton/FormButton";
import { useSelector } from "react-redux";
import { IRootState } from "../../..";

type ResolveAirtimePropType = {
  mobileNetwork: any;
  onProceed: SubmitHandler<FieldValues>;
  loading: boolean;
  netWorkLoading: boolean;
};

const ResolveAirtime = ({
  netWorkLoading,
  mobileNetwork,
  onProceed,
  loading,
}: ResolveAirtimePropType) => {
  const bill = useSelector((state: IRootState) => state.bill.value);

  const [networks, setNetworks] = useState([]);
  const { register, formState, handleSubmit, control } = useForm();

  useEffect(() => {
    setNetworks(
      mobileNetwork.map(
        (entry: {
          name: string;
          item_code: string;
          biller_code: string;
          biller_name: string;
        }) => ({
          label: entry.name,
          value: `${entry.biller_code} ${entry.item_code} ${entry.biller_name}`,
        })
      )
    );
  }, [mobileNetwork]);

  return (
    <div>
      <div className="font-bold text-xl md:text-2xl py-4">Internet / Data</div>
      <div className="text-gray-400 font-thin">
        Top up your internet and data
      </div>
      <form onSubmit={handleSubmit(onProceed)}>
        <FormInput
          defaultValue={bill.amount.toString()}
          formState={formState}
          label="Amount"
          name="amount"
          register={register}
          rules={{ required: "Enter amount" }}
          type="number"
          placeholder="1000"
        />
        <FormSelect
          placeholder={netWorkLoading ? "loading..." : "Select network"}
          label="Mobile Network"
          formState={formState}
          rules={{ required: "Select network" }}
          control={control}
          name="network"
          options={networks}
        />

        <FormInput
          formState={formState}
          label="Mobile Number"
          defaultValue={bill.customer.toString()}
          name="number"
          register={register}
          rules={{ required: "Enter number" }}
          type="number"
          placeholder="Enter mobile number"
        />
        <FormButton
          loading={loading}
          color="green"
          label={"Proceed"}
          type="big"
        />
      </form>
    </div>
  );
};

export default ResolveAirtime;
