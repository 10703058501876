import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../layout/Footer/Footer";
import Navigation from "../../layout/Navigation/Navigation";
import Search from "../../assests/icons/search.svg";
import { blogPost } from "./blogData";

const Blog = () => {
  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();

  return (
    <div>
      <Navigation page="Blog" />
      <div className="bg-[#00190D] md:bg-pattern pt-[90px] md:pt-32 pb-16">
        <div className="container mx-auto">
          <div className="flex flex-col justify-center items-center gap-9 px-4">
            <div className="font-black text-2xl md:text-[60px] text-white">
              The VT Money Blog
            </div>
            <div className="md:rounded-lg bg-white flex items-center px-4 py-3 gap-4 w-full md:w-fit">
              <img src={Search} alt="" />
              <input
                className="outline-none"
                type="text"
                placeholder="Search here"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container mx-auto my-10">
          <div className="flex flex-col justify-center items-center">
            {blogPost
              .slice(0, 1)
              .filter(
                (blog) =>
                  blog.title
                    .toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                  blog.exert.toLowerCase().includes(searchInput.toLowerCase())
              )
              .map((blog) => (
                <div
                  key={blog.blogId}
                  className="relative text-white px-3 mb-2"
                >
                  <img
                    src={blog.img}
                    alt=""
                    className="rounded-2xl h-[200px] md:h-full object-cover "
                    onClick={() => navigate(`/blog/${blog.blogId}`)}
                  />
                  <div className="absolute bottom-0 left-0 flex flex-col gap-4 md:w-[70%] px-7 md:p-5">
                    <div className=" text-lg md:text-xl font-black">
                      {blog.title}
                    </div>
                    <div className="text-sm md:text-lg font-normal">
                      {blog.exert}
                    </div>
                  </div>
                </div>
              ))}

            {/* <div className="flex flex-col md:flex-row  md:gap-10 md:p-24"> */}
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10 md:p-24">
              {blogPost
                .slice(1)
                .filter(
                  (blog) =>
                    blog.title
                      .toLowerCase()
                      .includes(searchInput.toLowerCase()) ||
                    blog.exert.toLowerCase().includes(searchInput.toLowerCase())
                )
                .map((blog) => (
                  <div
                    key={blog.blogId}
                    className="flex flex-col justify-center items-center bg-white p-3 gap-4"
                  >
                    <img
                      className="rounded-2xl h-[200px] md:h-full w-full object-cover"
                      src={blog.img}
                      alt=""
                      onClick={() => navigate(`/blog/${blog.blogId}`)}
                    />
                    <div className="flex flex-col gap-3 ">
                      <div className="text-lg md:text-xl font-black">
                        {blog.title}
                      </div>
                      <div className="text-sm md:text-lg font-normal">
                        {blog.text}
                      </div>
                    </div>
                    <div className="flex justify-between gap-4 items-center w-full">
                      <Link to={`/blog/${blog.blogId}`}>
                        <div className="text-lightGreen">Read More</div>
                      </Link>
                      <div>Nov 2022 | 2 mins</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
