import { useState } from "react";
import OtpInput from "react-otp-input";

import { ReactComponent as Spinner } from "../../../assests/icons/spinner.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetTransfer } from "../reducer";

import Transfer from "../../../utils/apiServices/transfer";
import { IRootState } from "../../..";
import { useNavigate } from "react-router-dom";
import notify from "../../../utils/notify";

const Pin = () => {
  const user = useSelector((state: IRootState) => state.transfer.value);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState("");
  const handleChange = (otp: string) => {
    setOtp(otp);
  };

  const handleSubmit = () => {
    setLoading(true);

    Transfer.sendUsername({
      amount: user.amount,
      bank_account_id: user.bank_account_id,
      note: user.note,
      username: user.username,
      pin: otp,
    })
      .then((res) => {
        if (res.status) {
          // console.log(res);
          setLoading(false);
          navigate("/dashboard");
          notify("success", "Successful");
          dispatch(resetTransfer());
        } else {
          setLoading(false);
          notify("error", res.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "check your internet connection");
      });
  };

  return (
    <div className="flex flex-col font-black gap-2">
      <div className="text-black  text-[22.5px]">Enter Transaction Pin</div>
      <div className="text-black  text-sm md:text-basem">
        Input your transaction pin to confirm this transaction
      </div>
      <div className="flex flex-col justify-center items-center gap-3 mt-10">
        <OtpInput
          isInputNum={true}
          value={otp}
          numInputs={4}
          onChange={handleChange}
          inputStyle={{
            width: "3rem",
            height: "3rem",
            margin: "0 1rem",
            fontSize: "16px",
            borderRadius: 4,
            color: "black",
            border: "1px solid #1EC677",
          }}
        />
        {error && <p className="text-red-500 self-center">Incorrect</p>}
      </div>
      <button
        className="flex justify-center items-center mt-10 rounded-md border-0 bg-lightGreen  text-white h-12 px-4 w-full"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading && <Spinner className="w-[30px] h-[30px] mr-2 stroke-white" />}{" "}
        Confirm
      </button>
    </div>
  );
};

export default Pin;
