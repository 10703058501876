import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assests/icons/Logo.svg";
import { ReactComponent as Menu } from "../../assests/icons/menu.svg";
import SideNavigation from "../SideNavigation/SideNavigation";

import "./Navigation.css";
type NavigationPropType = {
  page: string;
};
const Navigation = ({ page }: NavigationPropType) => {
  const [showSideNav, setShowSideNav] = useState(false);

  const navigationLinks = [
    {
      page: "PERSONAL",
      to: "/personal",
      text: "Personal",
    },
    {
      page: "BUSINESS",
      to: "/business",
      text: "Business",
    },
    {
      page: "CARD",
      to: "/card",
      text: "Card",
    },
    {
      page: "HELP",
      to: "/help",
      text: "Help",
    },
  ];

  const onClickMenu = () => {
    setShowSideNav(!showSideNav);
  };
  return (
    <>
      {showSideNav && <SideNavigation page={page} closeSideNav={onClickMenu} />}
      <div className="flex fixed top-0 bg-darkGreen justify-between items-center py-3 px-4 md:px-16 w-full z-[8] ">
        <div className="flex justify-between items-center container mx-auto">
          <div className="flex justify-center items-center">
            <div className="  ">
              <Link to="/">
                <img src={logo} alt="" className="w-28 md:w-32"/>
              </Link>
            </div>
            <div className="hidden lg:flex">
              {navigationLinks.map((item) => (
                <div className="relative ml-6 text-white" key={item.to}>
                  <Link
                    className={`${
                      page === item.page ? "text-transWhite7" : ""
                    }`}
                    to={item.to}
                  >
                    {item.text}
                  </Link>
                  {page === item.page && (
                    <div className="absolute left-[50%] h-[6px] w-[6px] rounded-full bg-yellow-400" />
                  )}
                </div>
              ))}
            </div>
          </div>
          {!showSideNav && (
            <div className="block lg:hidden">
              <Menu
                className="fill-white w-7 md:w-8 h-6"
                onClick={() => setShowSideNav(!showSideNav)}
              />
            </div>
          )}
          <div className="hidden md:flex justify-center items-center">
            <Link to="/login" className="text-white">
              Login
            </Link>
            <Link to="/signup">
              <button
                type="button"
                className="ml-6 rounded-md border-0 bg-lightGreen font-black text-white h-12 px-6 hover:scale-105"
              >
                Create an account
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
