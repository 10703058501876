/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import logo from "../../assests/icons/logoDark.svg";
import vector from "../../assests/images/contact-vector.png";
import FormInput from "../../components/FormComponents/FormInput/FormInput";
import FormButton from "../../components/FormComponents/FormButton/FormButton";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const { register, formState, handleSubmit } = useForm();

  const navigate = useNavigate();
  const onForgotPassword: SubmitHandler<FieldValues> = (formData) => {
    setLoading(true);
    // console.log(formData);

    setTimeout(() => {
      setLoading(false);
    }, 4000);
  };
  return (
    <div className="">
      <div className="container mx-auto py-5 mb-28  px-4">
        <Link to="/home">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="container mx-auto flex flex-col justify-center items-center px-7 md:px-0">
        <div className="relative">
          <img
            className="hidden md:block absolute -top-32 -right-52"
            src={vector}
            alt=""
          />
          <div className="text-[22.5px] font-black">Forgot your password?</div>
          <div className="flex text-base pt-4">
            Enter your email address and we'll send you a link to reset your
            password.
          </div>
          <form onSubmit={handleSubmit(onForgotPassword)}>
            <FormInput
              label="Email address"
              type="email"
              register={register}
              name="email"
              formState={formState}
              rules={{
                required: "Enter a valid email format",
              }}
            />
            <FormButton
              label="Proceed"
              type="big"
              color="green"
              error={false}
              loading={loading}
            />
          </form>
        </div>
        <Link
          className="self-center text-lightGreen font-black pt-6"
          to="/login"
        >
          Return To Log in
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
