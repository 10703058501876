/* eslint-disable react/jsx-props-no-spreading */
import { FieldValues, FormState, UseFormRegister } from "react-hook-form";

type FormTextFieldProps = {
  label: string;
  subLabel?: string;
  bottomLabel?: string;
  register: UseFormRegister<FieldValues>;
  name: string;
  placeholder?: string;
  formState: FormState<FieldValues>;
  rules: any;
  disabled?: boolean;
  defaultValue?: string;
  rows?: number; // optional
};

const FormTextField = ({
  label,
  subLabel, // optional
  bottomLabel, // optional
  register,
  name,
  placeholder, // optional
  formState,
  rules,
  disabled,
  defaultValue,
  rows = 4, // default number of rows
}: FormTextFieldProps) => {
  return (
    <div className="mt-8">
      <label className="text-[15px] md:text-[17px] font-medium" htmlFor={name}>
        {label}
      </label>
      {subLabel && <p className="text-[#B8B8B8] text-xs">{subLabel}</p>}
      <div className="relative">
        <textarea
          className="w-full rounded-[4px] text-[15px] md:text-base border border-gray-400 outline-none py-1 mt-2 pr-5 pl-5"
          placeholder={placeholder}
          disabled={disabled}
          defaultValue={defaultValue}
          rows={rows}
          {...register(name, rules)}
        />
      </div>
      {bottomLabel && <p>{bottomLabel}</p>}
      {formState.errors[name] && (
        <p className="text-red-500">
          {formState.errors[name]?.message?.toString()}
        </p>
      )}
    </div>
  );
};

export default FormTextField;
