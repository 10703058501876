import { lazy } from "react";

const Dashboard = lazy(() => import("../../pages/Dashboard/Dashboard"));
const Refer = lazy(() => import("../../pages/Dashboard/Refer"));
const Beneficiary = lazy(() => import("../../pages/Dashboard/Beneficiary"));
const Cards = lazy(() => import("../../pages/Dashboard/Cards"));
const MyAccount = lazy(() => import("../../pages/Dashboard/MyAccount"));
const Profile = lazy(() => import("../../pages/Dashboard/Profile"));
const Transactions = lazy(() => import("../../pages/Dashboard/Transactions"));
const NotFound = lazy(() => import("../../components/ui/page/NotFound"));

const dashboardRoute = [
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "beneficiaries/*",
    component: Beneficiary,
  },
  {
    path: "mycard/*",
    component: Cards,
  },
  {
    path: "account/",
    component: MyAccount,
  },
  {
    path: "account/:id",
    component: MyAccount,
  },
  {
    path: "profile",
    component: Profile,
  },
  {
    path: "transactions/*",
    component: Transactions,
  },
  {
    path: "refer",
    component: Refer,
  },
  {
    path: "*",
    component: NotFound,
  },
];

export default dashboardRoute;
