/* eslint-disable @typescript-eslint/no-unused-vars */

import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IRootState } from "../../..";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../components/FormComponents/FormInput/FormInput";
import FormButton from "../../../components/FormComponents/FormButton/FormButton";
import vector from "../../../assests/images/contact-vector.png";
import Img1 from "../../../assests/images/signup1.png";
import Img2 from "../../../assests/images/signup2.png";
import vector2 from "../../../assests/images/vecGreen.png";
import logo from "../../../assests/icons/logoDark.svg";
// import FormPhoneNumber from "../../../components/FormComponents/FormPhoneNumber/FormPhoneNumber";
import back from "../../../assests/icons/back.svg";

// Authentication

import Authentication from "../../../utils/apiServices/authentication";
import Account from "../../../utils/apiServices/myaccount";
import notify from "../../../utils/notify";
import { setBank } from "../../../features/dashbard/reducer";
import { login, setUser } from "../../../features/profile/reducer";

type SignupFormPropTYpe = {
  selectAccount: () => void;
  setPin: () => void;
  accountType: string;
};

const SignupForm = ({
  selectAccount,
  setPin,
  accountType,
}: SignupFormPropTYpe) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: IRootState) => state.user.value);
  const { uuid } = user;

  const [loading, setLoading] = useState(false);

  const { register, control, formState, handleSubmit, watch, reset } =
    useForm();

  const onSignUp: SubmitHandler<FieldValues> = (formData) => {
    setLoading(true);
    Authentication.signupComplete({
      email: formData.email,
      password: formData.password,
      password_confirmation: formData.confirm_password,
      type: accountType,
      uuid: uuid,
    })
      .then((res) => {
        if (res.status) {
          const { data } = res;
          // console.log(data);

          // Dispatch Login
          dispatch(
            login({
              id: data.user.id,
              uuid: data.user.uuid,
              firstname: data.user.firstname,
              business_name: data.user.business_name,
              business_name_suffix: data.user.business_name_suffix,
              middlename: data.user.middlename,
              lastname: data.user.lastname,
              profile_photo_url: data.user.profile_photo_url,
              email: data.user.email,
              phone: data.user.phone,
              country_code: data.user.country_code,
              country: data.user.country,
              state: data.user.state,
              city: data.user.city,
              dob: data.user.dob,
              type: data.user.type,
              access_token: data.token.access_token,
              has_kyc: data.user.has_kyc,
            })
          );

          // if Bnk exists, dispatch set Bank
          if (data.user.banks) {
            setBank({
              allBanksLoading: true,
            });
            Account.getAllAccounts()
              .then((res) => {
                if (res.status) {
                  const { data } = res;
                  // console.log(data);
                  dispatch(
                    setBank({
                      allBanks: data.map((bank: any) => ({
                        ...bank,
                        isOpen: false,
                      })),
                      name: data.account_name,
                      allBanksLoading: false,
                    })
                  );
                } else {
                }
              })
              .catch(() => {
                // console.log(res.message);
              });
          }
          setLoading(false);
          setPin();
        } else {
          setLoading(false);
          notify("error", res.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "Check your internet connection");
      });

    // console.log(formData);

    setTimeout(() => {
      setLoading(false);
      // setCompleted();
    }, 4000);
  };
  const watchPassword = useRef({});
  watchPassword.current = watch("password", "");
  return (
    <div className="">
      <div className="flex sticky top-0 z-[5] bg-white justify-between items-center text-black container mx-auto py-5 mb-10 px-4">
        <Link to="/">
          <img src={logo} alt="" className="w-28 md:w-32" />
        </Link>
        <Link className="font-medium" to="/login">
          Log in{" "}
        </Link>
      </div>
      <div className="flex container mx-auto items-center justify-around">
        <div className=" hidden lg:block self-start mt-28 relative">
          <img className="" src={Img1} alt="" />
          <img
            className="-z-[1] absolute w-[70%] -top-10 -left-8"
            src={vector}
            alt=""
          />
        </div>
        <div className=" flex flex-col text-black justify-center px-7 md:px-20 w-full md:w-[80%] lg:w-[45%]">
          <div className=" mx-auto container">
            <div
              className="flex justify-start mb-12 gap-2 items-center text-lightGreen cursor-pointer"
              onClick={() => selectAccount()}
            >
              <span>
                <img src={back} alt="" />
              </span>{" "}
              Go back
            </div>
            <div className="text-[22.5px] font-black">Final Step</div>
            <div className="flex text-base pt-2">
              Kindly fill the fields below to set up your account
            </div>
            <form onSubmit={handleSubmit(onSignUp)}>
              <FormInput
                label="Email address"
                type="email"
                register={register}
                name="email"
                rules={{ required: "Enter your Email address" }}
                formState={formState}
              />
              {/* <FormPhoneNumber
                label="Phone Number"
                name="phonenumber"
                register={register}
                formState={formState}
                rules={{
                  required: "Enter a valid Phone Number",
                  // pattern: {
                  //   value:
                  //     /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/,
                  //   message: "Enter valid phone",
                  // },
                }}
                value={value}
                onChange={() => setValue}
              /> */}
              <FormInput
                label="Password"
                placeholder="Password (min. of 8 characters)"
                type="password"
                register={register}
                name="password"
                rules={{
                  required: "Enter your Password",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                  pattern: {
                    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                    message:
                      "Passwords have to contain at least one uppercase and one lower case character and a number",
                  },
                }}
                formState={formState}
              />
              <FormInput
                label="Confirm Password"
                type="password"
                register={register}
                name="confirm_password"
                placeholder="Password (min. of 8 characters)"
                formState={formState}
                rules={{
                  required: "Confirm your Password",
                  validate: (value: string) =>
                    value === watchPassword.current ||
                    "The passwords do not match",
                }}
              />
              <FormButton
                label="Proceed"
                type="big"
                color="green"
                // error={formState.errors.terms_and_conditions}
                loading={loading}
              />
            </form>
          </div>
        </div>
        <div className="hidden lg:block self-end relative">
          <img className="" src={Img2} alt="" />
          <img
            className="-z-[1] absolute w-[70%] -bottom-20 -right-10"
            src={vector2}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
