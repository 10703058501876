import { useState } from "react";
import Footer from "../../layout/Footer/Footer";
import Navigation from "../../layout/Navigation/Navigation";
import { ReactComponent as Minus } from "../../assests/icons/minus.svg";
// import { ReactComponent as Plus } from "../../assests/icons/plus.svg";
import mask from "../../assests/images/mask_image.png";

const FAQs = () => {
  const [personalFaqs, setPersonalFaqs] = useState([
    {
      id: 1,
      open: false,
      isOn: false,
      heading: "How VTMoney works?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim. orem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim.",
    },
    {
      id: 2,
      open: false,
      isOn: false,
      heading: "How Ccan I get started?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim. orem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim.",
    },
    {
      id: 3,
      open: false,
      isOn: false,
      heading: "Wo uses VTMoney?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim. orem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim.",
    },
  ]);

  const [businessFaqs, setBusinesFaqs] = useState([
    {
      id: 1,
      open: false,
      isOn: false,
      heading: "How VTMoney works?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim. orem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim.",
    },
    {
      id: 2,
      open: false,
      isOn: false,
      heading: "How Ccan I get started?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim. orem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim.",
    },
    {
      id: 3,
      open: false,
      isOn: false,
      heading: "Wo uses VTMoney?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim. orem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nibh augue. Pelle ntesque vitae tristique enim.",
    },
  ]);

  const togglePersonalFaq = (id: number) => {
    setPersonalFaqs(
      personalFaqs.map((faq) =>
        faq.id === id ? { ...faq, open: !faq.open } : faq
      )
    );
  };

  const toggleBusinessFaq = (id: number) => {
    setBusinesFaqs(
      businessFaqs.map((faq) =>
        faq.id === id ? { ...faq, open: !faq.open } : faq
      )
    );
  };

  const toggleOnBusiness = (id: number) => {
    setBusinesFaqs(
      businessFaqs.map((faq) =>
        faq.id === id ? { ...faq, isOn: !faq.isOn } : faq
      )
    );
  };
  const toggleOnPersonal = (id: number) => {
    setPersonalFaqs(
      personalFaqs.map((faq) =>
        faq.id === id ? { ...faq, isOn: !faq.isOn } : faq
      )
    );
  };

  // const [isOn, setIsOn] = useState(false);
  return (
    <div>
      <Navigation page="FAQs" />
      <div className="">
        <div
          className="container mx-auto flex flex-col justify-center gap-4 md:gap-8 items-center text-[#1C1C1C] pt-32 md:pt-40 pb-0 md:pb-10
        "
        >
          <div className="font-black text-2xl md:text-[68px] text-center leading-none md:leading-[70px]">
            Frequently Asked Questions
          </div>
          <div className="font-medium text-base md:text-lg text-center px-4 md:px-0">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor
            <br className="hidden md:block" />
            incididunt ut labore et dolore magna aliqua.{" "}
          </div>
        </div>
        <div className="flex flex-col mx-auto container py-[50px] px-4 md:px-[100px] lg:px-[250px] ">
          <div className="text-[#1EC677] font-black text-lg md:text-[28.1px] justify-start items-start mb-2 md:mb-4">
            Personal
          </div>
          <div className="">
            {personalFaqs.map((item) => (
              <div
                key={item.id}
                className={`border-b-2 border-b-[#F4F4F4] py-3 px-0 md:px-5 cursor-pointer`}
                onClick={() => togglePersonalFaq(item.id)}
                onMouseOver={() => toggleOnPersonal(item.id)}
              >
                <div className="flex justify-between items-center ">
                  <div className="font-medium text-base md:text-lg">
                    {item.heading}
                  </div>
                  {!item.open ? (
                    <div
                      className={`rounded-sm bg-lightBlack w-6 h-6 hover:bg-black hover:text-white `}
                    >
                      <div className="hover:animate-wiggle text-center">+</div>
                    </div>
                  ) : (
                    <Minus className="hover:animate-wiggle" />
                  )}
                </div>
                <div
                  className={`pt-3 text-[#646464] text-[14.4px] ${
                    item.open ? "block" : "hidden"
                  }`}
                >
                  {item.content}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col mx-auto container py-[50px] px-4 md:px-[100px] lg:px-[250px] ">
          <div className="text-[#1EC677] font-black text-lg md:text-[28.1px] justify-start items-startmb-2 md:mb-4">
            Business
          </div>
          <div>
            {businessFaqs.map((item) => (
              <div
                key={item.id}
                className={`border-b-2 border-b-[#F4F4F4] py-3 px-0 md:px-5 cursor-pointer`}
                onClick={() => toggleBusinessFaq(item.id)}
                onMouseOver={() => {
                  toggleOnBusiness(item.id);
                }}
                onMouseLeave={() => toggleOnBusiness(item.id)}
              >
                <div className="flex justify-between items-center">
                  <div className="font-medium text-base md:text-lg">
                    {item.heading}
                  </div>
                  {!item.open ? (
                    // <Plus className="hover:animate-wiggle" />
                    <div
                      className={`text-center rounded-[4px] bg-lightBlack w-6 h-6  ${
                        item.isOn ? "bg-black text-white" : ""
                      }`}
                    >
                      <div
                        className={`text-center ${
                          item.isOn ? "animate-wiggle " : ""
                        }`}
                      >
                        +
                      </div>
                    </div>
                  ) : (
                    // <Minus className="fill-white" />
                    <div className="rounded-[4px]  w-6 h-6 bg-black text-white ">
                      <div className="hover:animate-wiggle text-center">-</div>
                    </div>
                  )}
                </div>
                <div
                  className={`pt-3 text-[#646464] text-[14.4px] ${
                    item.open ? "block" : "hidden"
                  }`}
                >
                  {item.content}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-lightGreen">
          <div className="container mx-auto relative flex flex-col justify-center md:justify-start items-center md:items-start text-white mt-10 md:mt-6 py-20 md:py-[109px] pl-0 md:pl-[188px]">
            <div className="font-black text-[23.44px] md:text-[35.16px] pt-8 md:pt-0 pb-4">
              Have additional inquiries?
            </div>
            <div className="font-medium text-base md:text-lg leading-6 pb-6 text-center md:text-start">
              Contact us at your convenience. Any questions would be gladly
              <br className="hidden md:block" />
              answered by us.
            </div>
            <button className="rounded-md border-0 bg-white text-base md:text-[14.4px] text-lightGreen h-10 md:h-12 px-4 md:px-6">
              Get in Touch
            </button>
            <img
              className="w-52 md:w-80 lg:block absolute -top-20 md:right-3  md:bottom-0"
              src={mask}
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQs;
