import { useParams } from "react-router-dom";
import Airtime from "./Airtime/Airtime";
import Electricity from "./Electricity/Electricity";
import TV from "./TV/TV";
import Data from "./Data/Data";

const QuickPayments = () => {
  const { page } = useParams();

  const displatPage = () => {
    switch (page) {
      case "airtime":
        return <Airtime />;
      case "electricity":
        return <Electricity />;
      case "cable":
        return <TV />;
      case "data":
        return <Data />;
      default:
        return <div></div>;
    }
  };
  return <div>{displatPage()}</div>;
};

export default QuickPayments;
