/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { CardPropType } from "./types";

const initialStateValue: CardPropType = {
  card: null,
  cardLoading: true,
  transactions: null,
  transactionLoading: true,
};

export const cardSlice = createSlice({
  name: "card",
  initialState: { value: initialStateValue },
  reducers: {
    setCard: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setCard } = cardSlice.actions;

export default cardSlice.reducer;
