import { useNavigate } from "react-router-dom";
import Footer from "../../layout/Footer/Footer";
import Navigation from "../../layout/Navigation/Navigation";

import CardA from "../.././assests/images/card-a.png";
import CardB from "../.././assests/images/card-b.png";
import CardC from "../.././assests/images/card-c.png";
import CardD from "../.././assests/images/card-d.png";

const Card = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Navigation page="CARD" />
      {/* Hero */}
      <div className="bg-white pt-[70px] md:pt-20 overflow-y-auto">
        <div className="container mx-auto ">
          <div className="flex flex-col md:flex-row relative justify-between items-center gap-7 mt-2 md:mt-12 px-4 py-16 md:py-0">
            <div className="flex flex-col justify-center items-start relative gap-6">
              <h1 className="md:leading-[62px] text-3xl md:text-[50.95px] text-black font-black">
                Get a VT Money Debit card <br className="hidden md:block" />
                <span className="text-lightGreen"> without limit.</span>
              </h1>
              <h5 className=" text-black leading-8 text-base md:text-lg font-medium">
                The simple debit card that saves you money.
              </h5>

              <button
                className="rounded border-0 bg-lightGreen font-black text-[14.4px] text-white h-12 px-6 w-fit"
                onClick={() => navigate("/signup")}
              >
                Get VTMoney card now
              </button>
            </div>
            <div className="hidden relative md:flex flex-col md:w-[40%]">
              <img className="w-[300px] z-[1]" src={CardA} alt="" />
              <img
                className="absolute bottom-0 right-10 w-[300px]"
                src={CardB}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {/* HERO2 */}
      <div className="bg-darkGreen">
        <div className="container mx-auto">
          <div className="flex flex-col justify-end items-center text-white py-8 md:py-20 px-5 gap-12">
            {/* 1 */}
            <div className="flex flex-col md:flex-row w-full items-center justify-between mb-10 gap-3">
              <div className="flex flex-col md:w-1/2 md:pr-28">
                <h3 className="mt-8 text-xl md:text-2xl font-black">
                  Get a business dollar debit card
                </h3>
                <h5 className="mt-3 leading-7">
                  Spend without limit with the VTMoney dollar debit card. We
                  offer you full control over your finances with our debit cards
                </h5>
              </div>
              <div className=" justify-end flex md:w-1/2 ">
                <img
                  src={CardC}
                  alt=""
                  className="md:w-[400px] md:h-[400px] z-[2]"
                />
              </div>
            </div>
            {/* 2 */}
            <div className="flex flex-col-reverse md:flex-row w-full items-center justify-between mb-10 gap-3">
              <div className=" justify-start flex md:w-1/2 ">
                <img
                  src={CardD}
                  alt=""
                  className="md:w-[400px] md:h-[400px] z-[2]"
                />
              </div>
              <div className="flex flex-col md:w-1/2 md:pl-28  justify-end">
                <h3 className="mt-8 text-xl md:text-2xl font-black">
                  Withdraw from your VTMoney card at any ATMs Nationwide
                </h3>
                <h5 className="mt-3 leading-7">
                  The VTMoney Visa card is acceptable across ATM in Nigeria.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Card;
