import React, { useState } from "react";
import { ReactComponent as Spinner } from "../../../assests/icons/spinner.svg";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../..";
import { setTransfer } from "../reducer";

type SendPagePropType = {
  onSend: () => void;
  loading: boolean;
};
const SendUSDPage = ({ loading, onSend }: SendPagePropType) => {
  const dispatch = useDispatch();
  const user = useSelector((state: IRootState) => state.transfer.value);
  const rate = useSelector((state: IRootState) => state.bank.value).rates;

  const [beneficiary, setBeneficiary] = useState(false);
  const onSetBeneficiary = (e: any) => {
    setBeneficiary(e.target.checked);
    dispatch(
      setTransfer({
        save_beneficiary: e.target.checked,
      })
    );
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="text-black font-black text-xl">
        Review details of your transfer
      </div>
      <div>Transfer</div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">You send exactly</div>
        <div className="font-bold md:text-base text-sm">{user.amount} NGN</div>
      </div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">Total fees (included)</div>
        <div className="font-bold md:text-base text-sm">{user.fee} NGN</div>
      </div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">
          Total amount we'll deduct
        </div>
        <div className="font-bold md:text-base text-sm">{user.amount} NGN</div>
      </div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">Exchange Rate</div>
        <div className="font-bold md:text-base text-sm">
          1 USD = {rate.usd} NGN
        </div>
      </div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">Recipient gets</div>
        <div className="font-bold md:text-base text-sm">
          {(user.amount / parseInt(rate.usd)).toFixed(2)} USD
        </div>
      </div>
      <div className="mt-4">Recipient Details</div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm whitespace-nowrap">
          Account Name
        </div>
        <div className="font-bold md:text-base text-sm w-[60%] md:w-full text-right">
          {user.name}
        </div>
      </div>
      {user.account_number && (
        <div className="flex justify-between">
          <div className="text-[#75757575] text-sm">IBAN</div>
          <div className="font-bold md:text-base text-sm ">
            {user.account_number}
          </div>
        </div>
      )}
      {user.bank_name && (
        <div className="flex justify-between">
          <div className="text-[#75757575] text-sm ">Bank Name</div>
          <div className="font-bold md:text-base text-sm">{user.bank_name}</div>
        </div>
      )}
      {user.country && (
        <div className="flex justify-between">
          <div className="text-[#75757575] text-sm ">Country</div>
          <div className="font-bold md:text-base text-sm">{user.country}</div>
        </div>
      )}
      {user.swift_code && (
        <div className="flex justify-between">
          <div className="text-[#75757575] text-sm ">Swift Code</div>
          <div className="font-bold md:text-base text-sm">
            {user.swift_code}
          </div>
        </div>
      )}
      <div className="mt-4">Description</div>
      <textarea
        className=" w-full rounded-[4px] border border-gray-400 outline-none  mt-1 p-5"
        style={{ resize: "none" }}
        rows={3}
        disabled
        value={user ? user.note : ""}
      ></textarea>
      {user.save_beneficiary && (
        <div className="flex items-center gap-2 mt-4 text-[15px] md:text-base">
          <input
            type="checkbox"
            name=""
            id=""
            checked={beneficiary}
            onChange={onSetBeneficiary}
          />
          <div className="">Save as beneficiary</div>
        </div>
      )}

      <button
        className="flex justify-center items-center mt-10 rounded-md border-0 bg-lightGreen font-black text-white h-12 px-4 w-full"
        onClick={onSend}
      >
        {loading && <Spinner className="w-[30px] h-[30px] mr-2 stroke-white" />}{" "}
        Confirm and Send
      </button>
    </div>
  );
};

export default SendUSDPage;
