import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler } from "react-hook-form";

import logo from "../../../assests/icons/logoDark.svg";
import back from "../../../assests/icons/back.svg";

import Account from "../../../utils/apiServices/myaccount";

import { useDispatch } from "react-redux";
import { setTransfer } from "../reducer";
import notify from "../../../utils/notify";
import ResolveUsername from "./ResolveUsername";
import AmountPage from "./AmountPage";
import Review from "./Review";
import Pin from "./Pin";
const Username = () => {
  function extractNumbers(str: any) {
    const match = str.match(/\d+/); // This regex matches sequences of digits
    return match ? match[0] : null; // Return the matched numbers or null if no match is found
  }

  function extractLetters(str: any) {
    const match = str.match(/\[([A-Z]+)\]/); // This regex matches sequences of letters within []
    return match ? match[1] : null; // Return the matched letters or null if no match is found
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [accounts, setAccounts] = useState<any>([]);
  const [proceed, setProceed] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState("resolve");
  const displayPage = () => {
    switch (currentPage) {
      case "resolve":
        return (
          <ResolveUsername
            verifyLoading={verifyLoading}
            onVerify={onVerify}
            onProceed={onSetAmount}
            proceed={proceed}
            accounts={accounts}
          />
        );
      case "amount":
        return <AmountPage onProceed={onSetReview} username="" />;
      case "review":
        return <Review onProceed={onSetPin} />;
      case "pin":
        return <Pin />;

      default:
        return <div></div>;
    }
  };

  const setResolve = () => {
    setCurrentPage("resolve");
    setProceed(false);
    setAccounts([]);
  };

  const setAmount = () => {
    setCurrentPage("amount");
  };

  const setReview = () => {
    setCurrentPage("review");
  };

  const onVerify: SubmitHandler<FieldValues> = (formData) => {
    setVerifyLoading(true);
    setUsername(formData.username);
    Account.geUsernameAccounts(formData.username)
      .then((res) => {
        if (res.status) {
          setAccounts(res.data);
          setVerifyLoading(false);
          setProceed(true);
        } else {
          notify("error", res.message);
          setVerifyLoading(false);
        }
      })
      .catch(() => {
        setVerifyLoading(false);
        notify("error", "Check internet connection");
      });
  };

  const onSetAmount: SubmitHandler<FieldValues> = (formData) => {
    dispatch(
      setTransfer({
        username: username,
        bank_account_id: formData.account.value,
        name: accounts[0].account_name,
        currency: extractLetters(formData.account.label),
        account_number: extractNumbers(formData.account.label),
      })
    );
    setCurrentPage("amount");
  };

  const onSetReview: SubmitHandler<FieldValues> = (formData) => {
    dispatch(
      setTransfer({
        username: username,
        amount: formData.amount,
        note: formData.description,
      })
    );
    setCurrentPage("review");
  };

  const onSetPin: SubmitHandler<FieldValues> = (formData) => {
    setCurrentPage("pin");
  };

  return (
    <div className="mb-10">
      <div className="flex sticky top-0 z-[5] bg-white justify-between items-center text-black container mx-auto py-5 mb-10 px-4 ">
        <Link to="/dashboard">
          <img src={logo} alt="" className="w-28 md:w-32" />
        </Link>
        <div
          className="flex justify-start gap-2 items-center text-lightGreen cursor-pointer text-sm md:text-base"
          onClick={() =>
            currentPage === "amount"
              ? setResolve()
              : currentPage === "review"
              ? setAmount()
              : currentPage === "pin"
              ? setReview()
              : navigate(-1)
          }
        >
          <span>
            <img src={back} alt="" />
          </span>{" "}
          Go back
        </div>
      </div>
      <div className="flex container mx-auto items-center justify-around bg-red">
        <div className=" flex flex-col text-black justify-center px-4 md:px-20 w-full md:w-[80%] lg:w-[55%] xl:[45%]">
          {displayPage()}
        </div>
      </div>
    </div>
  );
};

export default Username;
