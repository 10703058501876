import baseURL from "../baseUrl";
import getAccessToken from "../../accessToken";
import { TransferPinParameter, UpdatePasswordParameters } from "./types";

class Profile {
  async getProfile() {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/profile`, {
      method: "get",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.json();
  }

  async changePasswords(data: UpdatePasswordParameters) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/profile/update/password`, {
      method: "post",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async transactionPin(data: TransferPinParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/profile/pin/setup`, {
      method: "post",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async changeTransactionPin(data: TransferPinParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/profile/pin/update`, {
      method: "post",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }
}

const instance = new Profile();

export default instance;
