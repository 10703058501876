import React from "react";
import Footer from "../../layout/Footer/Footer";
import Navigation from "../../layout/Navigation/Navigation";

const Company = () => {
  return (
    <div>
      <Navigation page="COMPANY" />
      <div className="pt-20 md:pt-20">Company</div>
      <Footer />
    </div>
  );
};

export default Company;
