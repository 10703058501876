import { useNavigate } from "react-router-dom";

import Footer from "../../layout/Footer/Footer";
import Navigation from "../../layout/Navigation/Navigation";

import { ReactComponent as Arrow } from "../../assests/icons/arrow.svg";

import Mobile from "../../assests/images/mobile.png";
import World from "../../assests/images/world.png";
import CreateMobile from "../../assests/images/create-mobile.png";
import OwnCard from "../../assests/images/own.png";
import pos from "../../assests/images/vt-pos.png";
import Hero1 from "../../assests/images/hero1.png";
import bb from "../../assests/images/bb.png";
import save from "../../assests/images/save.png";
import new_a from "../../assests/images/new_a.png";
import ba from "../../assests/images/ba.png";
import GreenCard from "../../assests/images/h-vtcard-green.png";
import WhiteCard from "../../assests/images/h-vtcard-white.png";

import Send from "../../assests/icons/h-send.svg";
import Receive from "../../assests/icons/h-receive.svg";

const Business = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Navigation page="BUSINESS" />
      {/*  */}
      <div className="flex flex-col  justify-center bg-darkGreen pt-10  md:pt-20  overflow-y-auto">
        <div className="container mx-auto">
          <div className="flex flex-col text-white justify-center items-center gap-6 py-16 px-4">
            <div className="border-l-2 border-r-2 w-fit bg-transWhite1 px-5 md:px-12 py-3 text-transWhite">
              For Businesses
            </div>
            <div className="text-3xl text-center md:text-[50px] font-black">
              Open VTMoney Bank Account for free
            </div>
            <div className="text-sm md:text-lg font-normal md:w-1/2 text-center">
              Join the thousands of people on VTMoney and transform the way you
              accept payment from your customers everyday
            </div>
            <div className="flex flex-col md:flex-row justify-center md:justify-start md:items-start gap-4">
              <button
                className=" rounded-md border-0 bg-lightGreen font-black text-white h-12 px-6"
                onClick={() => navigate("/signup")}
              >
                Get Started
              </button>
              <button
                className=" rounded-md border-0 bg-white25 font-black text-white h-12 px-6"
                onClick={() => navigate("/signup")}
              >
                See how it works
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="bg-darkGreen">
        <div className="container mx-auto px-5 md:px-20">
          <img className="rounded-t-lg " src={Hero1} alt="" />
        </div>
      </div>
      {/*  */}
      <div className="bg-[#F7F7F7]">
        <div className="container mx-auto gap-5 flex flex-col items-center py-8 md:px-20">
          <div className="font-semibold text-lg md:text-xl my-5">
            Your Business Is Set To Go
          </div>
          <div className=" md:rounded-lg items-center gap-5 pt-5 md:pt-10 justify-between flex flex-col px-5 md:px-10 md:flex-row bg-white w-fit">
            <div className="flex items-center md:items-start flex-col gap-4 md:w-1/2">
              <div className="font-semibold text-xl text-center md:text-start md:text-2xl">
                Own a free bank account in minutes
              </div>
              <div className="font-normal text-base md:text-lg text-center md:text-justify">
                Say goodbye to branch visits and paperwork. With our streamlined
                account opening process, you can create an account in just a few
                minutes, all from the convenience of your phone.
              </div>
            </div>
            <div className="relative">
              <img src={CreateMobile} alt="" className="w-[300px]" />
              <img
                src={OwnCard}
                className="hidden md:block absolute -left-24 top-14"
                alt=""
              />
            </div>
          </div>
          {/*  */}
          <div className="md:rounded-lg items-center bg-darkGreen text-white gap-5 pt-10 justify-between flex flex-col px-5 md:px-10 md:flex-row w-fit">
            <div className="flex items-center md:items-start flex-col gap-4 md:w-[55%]">
              <div className="font-semibold text-xl text-center md:text-start md:text-2xl">
                Expand your <span className="text-[#D6C230]">horizons</span>{" "}
                with our <span className="text-[#D6C230]">fee-free</span>{" "}
                foreign exchange.
              </div>
              <div className="font-normal text-base md:text-lg text-center md:text-justify">
                Seamlessly go global and enjoy hassle-free currency exchange
                with no hidden fees.Send money to 20+ countries with no fees.
              </div>
            </div>
            <div className="">
              <img src={World} alt="" className="w-[300px]" />
            </div>
          </div>
        </div>
      </div>
      {/*  */}

      <div className="flex flex-col  bg-[#002A16] text-white">
        <div className="container mx-auto my-14">
          <div className="flex flex-col  gap-10 px-5 lg:px-10 2xl:px-20">
            {/*  */}
            <div className="flex flex-col-reverse md:flex-row justify-between">
              <div className="flex flex-col md:w-1/2 justify-center">
                <h3 className="mt-8 text-xl md:text-2xl font-black">
                  Pay friends on VTMoney for free,{" "}
                  <span className="text-[#D6C230]">globally.</span>
                </h3>
                <h5 className="mt-3 leading-7">
                  With no hidden fees and the ability to transfer funds in 20+
                  currencies, you'll enjoy instant and free transfers to other
                  VTMoney users, no matter where you are in the world.
                </h5>
              </div>

              <div className="flex justify-end md:w-1/2">
                <img
                  className=" lg:block md:h-[400px] md:w-[400px] "
                  src={new_a}
                  alt=""
                />
              </div>
            </div>
            {/*  */}
            <div className="flex flex-col md:flex-row justify-between">
              <div className="md:w-1/2">
                <img
                  className=" lg:block md:h-[400px] md:w-[400px] "
                  src={save}
                  alt=""
                />
              </div>
              <div className="flex flex-col md:w-1/2 justify-center">
                <h3 className="mt-8  text-xl md:text-2xl font-black">
                  <span className="text-[#D6C230]">Save as you spend</span> with
                  VTMoney.
                </h3>
                <h5 className="mt-3 leading-7">
                  Enjoy discounts and cashback offers from your favorite brands
                  with VTMoney. Simply use your VTMoney Debit card when you shop
                  and we'll connect you with exclusive savings opportunities.
                </h5>
              </div>
            </div>
            {/*  */}
            <div className="flex flex-col-reverse md:flex-row justify-between">
              <div className="flex flex-col md:w-1/2 justify-center">
                <h3 className="mt-8 text-xl md:text-2xl font-black">
                  Pay bills without the hassle.
                </h3>
                <h5 className="mt-3 leading-7">
                  From Meter top-ups, cable subscription , data bundle and
                  airtime purchase are ultimately convenience on VTMoney.
                </h5>
              </div>

              <div className="flex justify-end md:w-1/2">
                <img
                  className=" lg:block md:h-[400px] md:w-[400px] "
                  src={bb}
                  alt=""
                />
              </div>
            </div>
            {/*  */}
            <div className="flex flex-col md:flex-row justify-between">
              <div className="md:w-1/2">
                <img
                  className=" lg:block md:h-[400px] md:w-[400px] "
                  src={ba}
                  alt=""
                />
              </div>
              <div className="flex flex-col md:w-1/2 justify-center">
                <h3 className="mt-8  text-xl md:text-2xl font-black">
                  Finance tracking made simple
                </h3>
                <h5 className="mt-3 leading-7">
                  Streamline your finance tracking with our simple solution. Our
                  smart budgeting and analytics tools give you complete.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="bg-[#F9F9F9]">
        <div className="container mx-auto px-5 lg:px-56 flex flex-col justify-center items-center pb-10">
          <h1 className="text-[#1C1C1C] font-black text-start text-2xl md:text-4xl pt-10 md:pt-20 pb-20">
            Business solutions to scale your business
          </h1>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col-reverse md:flex-row justify-between items-center text-white rounded-3xl bg-pattern bg-cover  bg-repeat px-10">
              <div className="md:w-[30%] pt-5">
                <img className="w-[300px]" src={pos} alt="" />
              </div>
              <div className="flex flex-col  gap-y-4 pr-0 md:pr-20 md:w-[65%]">
                <h3 className="mt-8 text-xl md:text-2xl font-black">
                  POS Terminal
                </h3>
                <h5 className="mt-3 text-sm leading-7">
                  Accept payment from your clients with our POS terminals which
                  are secure and dependable for all your card transactions.
                </h5>
                <h5 className="flex items-center gap-3 mt-3 text-sm font-black text-lightGreen cursor-pointer leading-7">
                  Get POS Terminal
                  <span>
                    <Arrow />
                  </span>
                </h5>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-5">
              {/*  */}
              <div className="flex rounded-3xl relative bg-white px-10 md:w-[50%]">
                <div className="flex flex-col gap-y-4">
                  <h3 className="mt-8 text-xl md:text-2xl font-black">
                    Mobile Money Wallet
                  </h3>
                  <h5 className="mt-3 text-sm leading-7">
                    Create VTMoney mobile money wallet to manage every aspect of
                    your business and get easy access to your finances on-the-go
                  </h5>
                  <h5
                    className="flex items-center gap-3 mt-3 text-sm font-black text-lightGreen cursor-pointer leading-7"
                    onClick={() => navigate("/signup")}
                  >
                    Create mobile wallet account
                    <span>
                      <Arrow />
                    </span>
                  </h5>
                  <div className="relative mt-6">
                    <div className="flex  absolute justify-center items-center rounded-lg gap-2 -top-8 -right-8 shadow-sm bg-[#fafafa] text-xs z-[1] p-2">
                      <img src={Send} alt="" />
                      <div className="text-black">
                        You just sent{" "}
                        <span className="text-lightGreen">$620</span> to Dammy
                      </div>
                    </div>
                    <img className="" src={Mobile} alt="" />
                    <div className="flex  absolute justify-center items-center rounded-lg gap-2 top-[45%] -left-8 shadow-sm bg-[#fafafa] text-xs z-[1] p-2">
                      <img src={Receive} alt="" />
                      <div className="text-black">
                        <span className="text-lightGreen">$20,000</span>{" "}
                        deposited in your account
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="flex rounded-3xl relative bg-[#F5F5F5] px-10 md:w-[50%]">
                <div className="flex flex-col  gap-y-4">
                  <h3 className="mt-8 text-xl md:text-2xl font-black">
                    Debit Card
                  </h3>
                  <h5 className="mt-3 text-sm leading-7">
                    Bank without limits with our debit cards that allow for more
                    secure payments from your business accounts anywhere you
                    are.
                  </h5>
                  <h5
                    className="flex items-center gap-3 mt-3 text-sm font-black text-lightGreen cursor-pointer leading-7"
                    onClick={() => navigate("/signup")}
                  >
                    Get your debit card now
                    <span>
                      <Arrow />
                    </span>
                  </h5>
                  <div className="relative mt-5 z-[1]">
                    <img
                      className="absolute bottom-0 right-2 -z-[1]"
                      src={WhiteCard}
                      alt=""
                    />
                    <img className="" src={GreenCard} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Business;
