/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState } from "react";
import TwoFA from "./2FA/TwoFA";
import TwoFACode from "./2FACode/TwoFACode";
import AccountSelection from "./AccountSelection/AccountSelection";
import SignupCompleted from "./SignupCompleted/SignupCompleted";
import SignupForm from "./SignupForm/SignupForm";
import TransactionPinPage from "./TransferPin";
type DetailsType = {
  country: string;
  number: string;
};
const Signup = () => {
  const [currentPage, setCurrentPage] = useState<
    "form" | "selectaccount" | "2FA" | "2FAcode" | "completed" | "pin"
  >("2FA");

  const [details, setDetails] = useState<DetailsType>();
  const [accountType, selectAccountType] = useState("");

  const selectAccount = () => {
    setCurrentPage("selectaccount");
  };

  const bactToForm = (account: string) => {
    selectAccountType(account);
    setCurrentPage("form");
  };

  const set2FA = () => {
    setCurrentPage("2FA");
  };

  const set2FACode = (details?: DetailsType) => {
    setDetails(details);
    setCurrentPage("2FAcode");
  };

  const setPin = () => {
    setCurrentPage("pin");
  };

  const setCompleted = () => {
    setCurrentPage("completed");
  };

  const displayPage = () => {
    switch (currentPage) {
      case "2FA":
        return <TwoFA set2FACode={set2FACode} selectAccount={selectAccount} />;
      case "2FAcode":
        return (
          <TwoFACode
            set2FA={set2FA}
            details={details}
            selectAccount={selectAccount}
          />
        );
      case "selectaccount":
        return <AccountSelection bactToForm={bactToForm} set2FA={set2FA} />;
      case "form":
        return (
          <SignupForm
            selectAccount={selectAccount}
            accountType={accountType}
            setPin={setPin}
          />
        );
      case "pin":
        return <TransactionPinPage setCompleted={setCompleted} />;
      case "completed":
        return <SignupCompleted />;
      default:
        return <p>no page</p>;
    }
  };

  return <div className="">{displayPage()}</div>;
};

export default Signup;
