import { useNavigate } from "react-router-dom";
import Footer from "../../layout/Footer/Footer";
import Navigation from "../../layout/Navigation/Navigation";

import Wallet from "../../assests/images/mobile_wallet.png";

import a from "../../assests/images/p-a.png";
import OwnCard from "../../assests/images/own.png";
import Bank from "../../assests/images/bank.png";

import Pixels from "../../assests/images/pexels-tima.png";
import Support from "../../assests/images/support.png";
import Security from "../../assests/images/security.png";
import Globally from "../../assests/images/globally.png";

import greenDot from "../../assests/images/greenDot.png";
import yellowDot from "../../assests/images/yellowDot.png";

const Personal = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Navigation page="PERSONAL" />
      {/* < */}
      <div className="flex flex-col  justify-center bg-personalBg pt-10  md:pt-20  overflow-y-auto">
        <div className="container mx-auto">
          <div className="flex flex-col text-white justify-center items-center gap-6 py-16 md:py-24 px-4">
            <div className="border-l-2 border-r-2 w-fit bg-transWhite1 px-5 md:px-12 py-3 text-transWhite">
              PERSONAL
            </div>
            <div className="text-3xl text-center md:text-[50px] font-black md:leading-[60px]">
              Open VTMoney Bank account
            </div>
            <div className="text-sm md:text-lg font-normal lg:w-1/2 text-center">
              Say goodbye to waiting times, hidden fees, and paperwork with us.
              We understand your frustration and offer a bank account with a
              virtual card and 24/7 support, so you can manage your money on the
              go with ease.
            </div>
            <div className="flex flex-col md:flex-row justify-center md:justify-start md:items-start gap-4">
              <button
                className=" rounded-md border-0 bg-lightGreen font-black text-white h-12 px-6"
                onClick={() => navigate("/signup")}
              >
                Get Started
              </button>
              <button
                className=" rounded-md border-0 bg-white25 font-black text-white h-12 px-6"
                onClick={() => navigate("/signup")}
              >
                See how it works
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="bg-[#002A16]">
        <div className="container mx-auto">
          <div className="items-center  text-white gap-5 pt-5 md:pt-16 justify-between flex flex-col px-5 md:px-16 md:flex-row w-fit">
            <div className="flex items-center md:items-start flex-col gap-4 md:w-[55%]">
              <div className="font-semibold text-xl text-center md:text-start md:text-2xl">
                Own a Bank account in Minutes
              </div>
              <div className="font-normal text-base md:text-lg text-center md:text-justify">
                Say goodbye to branch visits and paperwork. With our streamlined
                account opening process, you can create an account in just a few
                minutes, all from the convenience of your phone.
              </div>
            </div>
            <div className="relative">
              <img src={Wallet} alt="" className="w-[300px]" />
              <img
                src={OwnCard}
                className="hidden lg:block absolute -left-32 top-20"
                alt=""
              />{" "}
              <img
                src={Bank}
                className="hidden lg:block absolute -left-24 top-1"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div className=" bg-white text-black">
        <div className="container mx-auto">
          <div className="flex flex-col justify-center items-center gap-3 md:gap-10 pt-20 pb-24 px-5 xl:px-10 2xl:px-20">
            {/* 2 */}
            <div className="flex flex-col md:flex-row w-full items-center justify-between mb-10 gap-3">
              <div className=" flex md:w-1/2 md:pl-4">
                <div className="flex relative w-fit">
                  <img
                    src={a}
                    alt=""
                    className="rounded-xl md:w-[400px] md:h-[400px] z-[2]"
                  />
                  <img
                    alt=""
                    src={yellowDot}
                    className="hidden lg:block absolute -top-8 -right-8 rounded-xl "
                  />

                  <img
                    src={greenDot}
                    alt=""
                    className="hidden lg:block absolute -bottom-8 -left-8 rounded-xl "
                  />
                </div>
              </div>
              <div className="flex flex-col md:w-1/2">
                <h3 className="mt-8 text-xl md:text-2xl font-black">
                  Stay tuned with push-notifications
                </h3>
                <h5 className="mt-3 leading-7">
                  Keep track of every transaction that comes in and out of your
                  bank account, thanks to real-time push notifications. Whether
                  you’re withdrawing or depositing money, making a transfer or
                  completing a monthly standing order—you’re always kept
                  up-to-date.
                </h5>
              </div>
            </div>

            {/*  */}
            <div className="flex flex-col-reverse md:flex-row justify-between">
              <div className="flex flex-col md:w-1/2 justify-center">
                <h3 className="mt-8 text-xl md:text-2xl font-black">
                  Make payments the easy way globally
                </h3>
                <h5 className="mt-3 leading-7">
                  VTMoney Card lets you make secure, contactless payments
                  online, in stores, or even in-app. Each transaction is
                  verified, and takes place in real-time. It’s the simple, safe,
                  100% mobile way to pay.
                </h5>
              </div>

              <div className="flex justify-end md:w-1/2">
                <img
                  className=" lg:block md:h-[400px] md:w-[400px] "
                  src={Globally}
                  alt=""
                />
              </div>
            </div>
            {/*  */}
            <div className="flex flex-col md:flex-row justify-between">
              <div className="mt-8 flex md:w-1/2 md:pl-4">
                <div className="flex relative w-fit">
                  <img
                    src={Support}
                    alt=""
                    className="rounded-xl md:w-[400px] md:h-[400px] z-[2]"
                  />
                  <img
                    alt=""
                    src={yellowDot}
                    className="hidden lg:block absolute -top-8 -right-8 rounded-xl "
                  />

                  <img
                    src={greenDot}
                    alt=""
                    className="hidden lg:block absolute -bottom-8 -left-8 rounded-xl "
                  />
                </div>
              </div>
              <div className="flex flex-col md:w-1/2 justify-center">
                <h3 className="mt-8  text-xl md:text-2xl font-black">
                  24/7 Customer Support
                </h3>
                <h5 className="mt-3 leading-7">
                  Our customer support team is always on hand to help you. Just
                  reach out to them via email or chat to an customer support
                  expert right in your app.
                </h5>
              </div>
            </div>
            {/*  */}
            <div className="flex flex-col-reverse md:flex-row justify-between">
              <div className="flex flex-col md:w-1/2 justify-center">
                <h3 className="mt-8 text-xl md:text-2xl font-black">
                  Security is a top priority at VTMoney
                </h3>
                <h5 className="mt-3 leading-7">
                  At VTMoney, we take security very seriously. You can open bank
                  account with our partnering bank in less than five minutes.
                  Additionally, our advanced security measures provide extra
                  safety and peace of mind when making payment, both in stores
                  and online.
                </h5>
              </div>

              <div className="mt-8 flex justify-end md:w-1/2">
                <img
                  className=" lg:block md:h-[400px] md:w-[400px] "
                  src={Security}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="bg-darkGreen">
        <div className="container mx-auto">
          <div className="items-center text-white gap-8 pt-5 md:pt-12 justify-between flex flex-col  md:pl-12 md:flex-row w-fit">
            <div className="flex items-center md:items-start flex-col gap-4 md:w-[55%] px-4 md:px-0">
              <div className="font-semibold text-xl text-center md:text-start md:text-2xl">
                Save and Spend Smartly
              </div>
              <div className="font-normal text-base md:text-lg text-center md:text-justify">
                The bank account that puts you in charge of your money.With our
                card program you can request debit card and take control of your
                finances. Gain valuable insights into your spending habits with
                our Insights feature and improve your budgeting skills along the
                way.
              </div>
            </div>
            <div className="relative">
              <img src={Pixels} alt="" className="" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bg-[#F9F9F9]">
        <div className="container mx-auto px-5 lg:px-56 flex flex-col justify-center items-center pb-10">
          <h1 className="text-[#1C1C1C] font-black text-start text-2xl md:text-4xl pt-10 md:pt-20 pb-20">
            Business solutions to scale your business
          </h1>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col-reverse md:flex-row justify-between items-center text-white rounded-3xl bg-pattern bg-cover  bg-repeat px-5 md:px-10">
              <div className="md:w-[30%] pt-5">
                <img className="w-[300px]" src={pos} alt="" />
              </div>
              <div className="flex flex-col  gap-y-4 pr-0 md:pr-20 md:w-[65%]">
                <h3 className="mt-8 text-xl md:text-2xl font-black">
                  POS Terminal
                </h3>
                <h5 className="mt-3 text-sm leading-7">
                  Accept payment from your clients with our POS terminals which
                  are secure and dependable for all your card transactions.
                </h5>
                <h5 className="flex items-center gap-3 mt-3 text-sm font-black text-lightGreen cursor-pointer leading-7">
                  Get POS Terminal
                  <span>
                    <Arrow />
                  </span>
                </h5>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-5">
              <div className="flex rounded-3xl relative bg-white px-5 md:px-10 md:w-[50%]">
                <div className="flex flex-col gap-y-4">
                  <h3 className="mt-8 text-xl md:text-2xl font-black">
                    Mobile Money Wallet
                  </h3>
                  <h5 className="mt-3 text-sm leading-7">
                    Create VTMoney mobile money wallet to manage every aspect of
                    your business and get easy access to your finances on-the-go
                  </h5>
                  <h5
                    className="flex items-center gap-3 mt-3 text-sm font-black text-lightGreen cursor-pointer leading-7"
                    onClick={() => navigate("/signup")}
                  >
                    Create mobile wallet account
                    <span>
                      <Arrow />
                    </span>
                  </h5>
                  <div className="relative mt-6">
                    <div className="flex  absolute justify-center items-center rounded-lg gap-2 -top-8 -right-9 md:-right-8 shadow-sm bg-[#fafafa] text-xs z-[1] p-2">
                      <img src={Send} alt="" />
                      <div className="text-black">
                        You just sent{" "}
                        <span className="text-lightGreen">$620</span> to Dammy
                      </div>
                    </div>
                    <img className="" src={Mobile} alt="" />
                    <div className="flex  absolute justify-center items-center rounded-lg gap-2 top-[45%] -left-9 md:-left-8 shadow-sm bg-[#fafafa] text-xs z-[1] p-2">
                      <img src={Receive} alt="" />
                      <div className="text-black">
                        <span className="text-lightGreen">$20,000</span>{" "}
                        deposited in your account
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex rounded-3xl relative bg-[#F5F5F5] px-5 md:px-10 md:w-[50%]">
                <div className="flex flex-col  gap-y-4">
                  <h3 className="mt-8 text-xl md:text-2xl font-black">
                    Debit Card
                  </h3>
                  <h5 className="mt-3 text-sm leading-7">
                    Bank without limits with our debit cards that allow for more
                    secure payments from your business accounts anywhere you
                    are.
                  </h5>
                  <h5
                    className="flex items-center gap-3 mt-3 text-sm font-black text-lightGreen cursor-pointer leading-7"
                    onClick={() => navigate("/signup")}
                  >
                    Get your debit card now
                    <span>
                      <Arrow />
                    </span>
                  </h5>
                  <div className="relative mt-5 z-[1]">
                    <img
                      className="absolute bottom-0 right-2 -z-[1]"
                      src={WhiteCard}
                      alt=""
                    />
                    <img className="" src={GreenCard} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default Personal;
