import getAccessToken from "../../accessToken";
import baseURL from "../baseUrl";
import {
  GetBillerPropType,
  PayBillParamaters,
  ValidateCustomerBillerparameters,
} from "./types";

class Bills {
  async getBillers(data: GetBillerPropType) {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${baseURL}/bills/categories/${data.type}?biller=${data.biller}`,
      {
        method: "get",
        headers: {
          "X-VTMoney-Client": "web-user",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.json();
  }

  async validateCusomer(data: ValidateCustomerBillerparameters) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/bills/validate`, {
      method: "post",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async payBill(data: PayBillParamaters) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/bills/pay`, {
      method: "post",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }
}

const instance = new Bills();
export default instance;
