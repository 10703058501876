import { useState, useMemo } from "react";
import { Control, Controller, FieldValues, FormState } from "react-hook-form";
import Select from "react-select";
import countryList from "react-select-country-list";
import "./FormCountrySelect.css";

type FormCountrySelectProps = {
  label?: string;
  subLabel?: string;
  bottomLabel?: string;
  control: Control<FieldValues, any> | undefined;
  name: string;
  placeholder?: string;
  formState: FormState<FieldValues>;
  rules: any;
  disabled?: boolean;
  multi?: boolean;
};

function FormCountrySelect({
  label,
  subLabel, // optional
  bottomLabel, // optional
  control,
  name,
  placeholder, // optional
  formState,
  rules,
  disabled,
  multi,
}: FormCountrySelectProps) {
  // const [value, setValue] = useState("");
  const options = useMemo<any>(() => countryList().getData(), []);

  // const changeHandler = (value: string) => {
  //   setValue(value);
  // };

  return (
    <div className="FormSelect FormInput">
      <label htmlFor={name}>{label}</label>
      {subLabel && <p className="subLabel">{subLabel}</p>}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            options={options}
            onChange={onChange}
            value={value}
            onBlur={onBlur}
            placeholder={placeholder}
            isDisabled={disabled}
            isMulti={multi}
          />
        )}
      />
      {bottomLabel && <p className="bottom-label">{bottomLabel}</p>}
      {formState.errors[name] && (
        <p className="text-red-500">
          {formState.errors[name]?.message?.toString()}
        </p>
      )}
    </div>
    // <div className="FormSelects">

    //   {/* <Select options={options} value={value} onChange={() => changeHandler} /> */}
    // </div>
  );
}

export default FormCountrySelect;
