import userReducer from "./features/profile/reducer";
import cardReducer from "./features/cards/reducer";
import kycReducer from "./pages/KYC/reducer";
import beneficiaryReducer from "./features/beneficiary/reducer";
import accountReducer from "./features/myaccount/reducer";
import transactionReducer from "./features/transactions/reducer";
import bankReducer from "./features/dashbard/reducer";
import transferReducer from "./pages/SendFunds/reducer";
import billReducer from "./pages/QuickPayments/reducer";

const rootReducer = {
  card: cardReducer,
  beneficiary: beneficiaryReducer,
  user: userReducer,
  kyc: kycReducer,
  account: accountReducer,
  bank: bankReducer,
  transfer: transferReducer,
  transaction: transactionReducer,
  bill: billReducer,
};

export default rootReducer;
