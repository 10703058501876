import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router from "./router/Router";
import RootLoading from "./components/RootLoading/RootLoading";
import { IRootState } from ".";

// Api Service
import Profile from "./utils/apiServices/profile";
import Transfer from "./utils/apiServices/transfer";
import Account from "./utils/apiServices/myaccount";
import { login } from "./features/profile/reducer";
import { setBank } from "./features/dashbard/reducer";
import notify from "./utils/notify";
import { setAccounts } from "./features/myaccount/reducer";
// import notify from "./utils/notify";

// Redux Action

function App() {
  const [rootLoading, setRootLoading] = useState(true);
  const user = useSelector((state: IRootState) => state.user.value);
  const { loggedIn } = user;

  // dispatch
  const dispatch = useDispatch();

  // access token
  const accessToken =
    localStorage.getItem("accessToken") ||
    sessionStorage.getItem("accessToken") ||
    "";

  // get profile
  useEffect(() => {
    if (accessToken && !loggedIn) {
      Transfer.getRate()
        .then((res) => {
          if (res.status) {
            const { data } = res;
            // console.log(data);
            dispatch(
              setBank({
                rates: data,
              })
            );
          } else {
            notify("error", res.message);
          }
        })
        .catch(() => {});
      Profile.getProfile()
        .then((res) => {
          // console.log("Profile is", res);
          if (res.status) {
            const { data } = res;
            // console.log(data);

            const localToken = localStorage.getItem("accessToken");
            // Dispatch login
            dispatch(
              login({
                id: data.user.id,
                uuid: data.user.uuid,
                card: data.user.card,
                username: data.user.username,
                banks: data.user.banks,
                firstname: data.user.firstname,
                business_name: data.user.business_name,
                business_name_suffix: data.user.business_name_suffix,
                middlename: data.user.middlename,
                lastname: data.user.lastname,
                name: data.user.name,
                type: data.user.type,
                email: data.user.email,
                phone: data.user.phone,
                country_code: data.user.country_code,
                profile_photo_url: data.user.profile_photo_url,
                country: data.user.country,
                state: data.user.state,
                dob: data.user.dob,
                city: data.user.city,
                address: data.user.address,
                postal_code: data.user.postal_code,
                has_kyc: data.user.has_kyc,
                has_card: data.user.has_card,
                has_pin: data.user.has_pin,
                has_username: data.user.has_username,
                access_token: accessToken,
                loggedIn: true,
                remember_me: !!localToken,
              })
            );

            // if Bnk exists, dispatch set Bank
            if (data.user.banks) {
              setBank({
                allBanksLoading: true,
              });
              Account.getAllAccounts()
                .then((res) => {
                  if (res.status) {
                    const { data } = res;
                    // console.log(data);
                    dispatch(
                      setBank({
                        allBanks: data.map((bank: any) => ({
                          ...bank,
                          isOpen: false,
                        })),
                        name: data.account_name,
                        allBanksLoading: false,
                      })
                    );
                    dispatch(setAccounts({ accounts: data }));
                  } else {
                  }
                })
                .catch(() => {
                  // console.log(res.message);
                });
            }
            setRootLoading(false);
          } else {
            setRootLoading(false);
            // notify("error", `${res.message}`);
          }
        })
        .catch(() => {
          setRootLoading(false);

          // console.log(err);
        });
    } else {
      setRootLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  return <div className="">{rootLoading ? <RootLoading /> : <Router />}</div>;
}

export default App;
