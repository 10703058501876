import React from "react";
import Footer from "../../layout/Footer/Footer";
import Navigation from "../../layout/Navigation/Navigation";

const PrivacyPolicy = () => {
  return (
    <div>
      <Navigation page="Privacy Policy" />
      <div className=" bg-darkGreen">
        <div
          className="container mx-auto flex flex-col justify-center items-center text-white gap-4 md:gap-8 pt-24 pb-10 md:pb-20 px-4 md:px-0
        "
        >
          <div className="font-black text-2xl md:text-[68px]">
            Privacy Policy
          </div>
          <div className="font-medium text-xs md:text-lg leading-5 md:leading-0 text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor
            <br />
            incididunt ut labore et dolore magna aliqua.{" "}
          </div>
        </div>
      </div>
      <div className="container mx-auto text-[#1C1C1C] py-10 md:py-[90px] lg:py-[130px] px-4 md:px-[100px] lg:px-[250px]">
        <div className="font-black text-[18.75px] md:text-[35px] mb-6">
          Privacy Policy
        </div>
        <div className="text-xs md:text-lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
          rhoncus, lorem vel pretium viverra, augue felis cursus enim, at cursus
          dui elit sit amet justo. In sit amet facilisis augue, id suscipit
          lacus. Nullam facilisis, quam et venenatis iaculis, quam ante
          porttitor elit, et auctor enim tortor quis nulla. Etiam eget risus in
          arcu convallis blandit vitae vulputate est. Quisque efficitur aliquet
          enim at pharetra. Integer posuere dolor id justo cursus, nec rutrum
          justo iaculis. Mauris quis tincidunt lorem. <br />
          <br />
          Suspendisse ullamcorper eros vitae mauris lobortis, id blandit leo
          consequat. In nec odio scelerisque, hendrerit lorem nec, vulputate
          purus. Aliquam quis risus sem. Nunc non orci lacus. Vestibulum
          vehicula leo quis suscipit ullamcorper. Phasellus sagittis in ligula
          cursus varius. Suspendisse diam elit, ornare a fermentum in, molestie
          at dui. Donec non efficitur diam. Praesent id enim non mi auctor
          finibus. Quisque a egestas mi, in scelerisque neque. Maecenas
          fringilla, dolor id lacinia convallis, quam dolor rhoncus erat, ac
          volutpat nulla risus eu dolor. Sed accumsan odio sit amet leo accumsan
          malesuada. Pellentesque ac malesuada ligula, vitae viverra odio.
          Aenean pretium enim et nunc suscipit, in congue dui elementum. Nullam
          erat sapien, fermentum vel nibh sit amet, sollicitudin consectetur
          justo. <br />
          <br /> Nullam metus lorem, pretium eget semper sit amet, dapibus vel
          sapien. Curabitur tincidunt mi ut leo scelerisque elementum. Phasellus
          dolor ligula, pharetra id vulputate ut, elementum quis nisi. Etiam
          rutrum quam ac eleifend viverra. Nulla facilisi. Pellentesque a tempor
          nibh. Duis tincidunt consectetur pretium. Phasellus ultrices quam sit
          amet diam volutpat, quis bibendum arcu pharetra. Vestibulum rhoncus
          orci a tellus ultricies cursus. <br />
          <br /> Quisque varius augue sapien, vitae vehicula mauris placerat
          vitae. Duis at euismod libero. Curabitur lacinia enim at nulla
          sagittis, vitae porta leo elementum. Maecenas vitae massa hendrerit,
          iaculis metus at, cursus sapien. Mauris aliquam, nisi accumsan maximus
          pharetra, felis mauris condimentum mi, id ultrices ex libero nec arcu.
          Aenean ornare turpis eu lorem euismod auctor. Integer vitae diam quis
          tellus ultricies placerat at at sapien. Sed facilisis ligula eget
          auctor ultricies. Nullam ante quam, vestibulum nec vulputate vel,
          euismod eu dolor. Cras ut nisl vitae orci luctus fringilla in at est.
          Aenean vel metus non lacus suscipit consectetur. Ut molestie ligula
          eros, ac vestibulum ex porttitor a.
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
