import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type RedirectPropType = {
  redirectUrl?: string;
};
const Redirect = ({ redirectUrl }: RedirectPropType) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectUrl) {
      navigate(redirectUrl);
    }
    // eslint-disable-next-line
  }, []);

  return null;
};

export default Redirect;
