/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import vector from "../../../assests/images/contact-vector.png";
import Img1 from "../../../assests/images/signup1.png";
import Img2 from "../../../assests/images/signup2.png";
import vector2 from "../../../assests/images/vecGreen.png";
import logo from "../../../assests/icons/logoDark.svg";
import FormPhoneNumber from "../../../components/FormComponents/FormPhoneNumber/FormPhoneNumber";
import FormButton from "../../../components/FormComponents/FormButton/FormButton";
import notify from "../../../utils/notify";
import FormCountrySelect from "../../../components/FormComponents/FormCountrySelect/FormCountrySelect";

// Authentication
import Authentication from "../../../utils/apiServices/authentication";
import { setUser } from "../../../features/profile/reducer";

type DetailsType = {
  country: string;
  number: string;
};

type TwoFAPropType = {
  set2FACode: (details: DetailsType) => void;
  selectAccount: () => void;
};

const TwoFA = ({ set2FACode, selectAccount }: TwoFAPropType) => {
  const dispach = useDispatch();

  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState();

  const { control, register, formState, handleSubmit } = useForm();
  const onSendNumber: SubmitHandler<FieldValues> = (formData) => {
    setLoading(true);
    Authentication.signupInitialize({
      country_code: "ng",
      phone: formData.phonenumber,
    })
      .then((res) => {
        const { data } = res;
        if (res.status) {
          set2FACode({
            number: formData.phonenumber,
            country: "ng",
          });
          setLoading(false);
          notify("success", `otp sent to ${formData.phonenumber}`);
        } else {
          // setLoading(false);
          notify("success", res.message);
          if (data.step) {
            if (data.step === "verify") {
              set2FACode({
                number: formData.phonenumber,
                country: "ng",
              });
              setLoading(false);
            }
            if (data.step === "complete") {
              dispach(setUser({ uuid: data.uuid }));
              selectAccount();
              setLoading(false);
            }
          }
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "An error occured. Check your connection");
      });
  };

  return (
    <div className="h-screen  relative">
      <div className="flex sticky top-0 z-[5] bg-white justify-between items-center text-black container mx-auto py-5 mb-10 px-4">
        <Link to="/">
          <img src={logo} alt="" className="w-28 md:w-32" />
        </Link>
        <Link className="font-medium" to="/login">
          Log in{" "}
        </Link>
      </div>
      <div className="flex container mx-auto items-center justify-around">
        <div className=" hidden lg:block self-start mt-28 relative">
          <img className="" src={Img1} alt="" />
          <img
            className="-z-[1] absolute w-[70%] -top-10 -left-8"
            src={vector}
            alt=""
          />
        </div>
        <div className="flex flex-col text-black justify-center px-7 md:px-20 w-full md:w-[80%] lg:w-[50%]">
          <div className=" mx-auto container">
            <div className="text-[22.5px] font-black">Let's get Started</div>
            <div className="text-base pt-2">
              Kindly provide your phone Number.
            </div>
            <div className="border-2 mt-4 border-red-300 p-4 text-red-300">
              <span className="font-black">IMPORTANT:</span> Ensure phone number
              is linked to BVN
            </div>
            {/*  */}
            <div className="mt-16">
              <form onSubmit={handleSubmit(onSendNumber)}>
                {/* <FormCountrySelect
                  label="Select country of residence"
                  control={control}
                  formState={formState}
                  name="country"
                  rules={{ required: "Select Country" }}
                /> */}
                <FormPhoneNumber
                  defaultCountry="NG"
                  placeholder=""
                  label="Phone number"
                  name="phonenumber"
                  register={register}
                  formState={formState}
                  rules={
                    {
                      // required: "Enter a valid Phone Number",
                    }
                  }
                  value={value}
                  onChange={() => setValue}
                />
                <FormButton
                  label="Send Verification Code"
                  type="big"
                  color="green"
                  error={false}
                  loading={loading}
                />
              </form>
            </div>
          </div>
        </div>
        <div className=" -bottom-16 right-3 lg:relative self-end relative">
          <div className="hidden lg:block self-start mt-28 relative">
            <img className="" src={Img2} alt="" />
            <img
              className="-z-[1] absolute w-[70%] -bottom-20 -right-10"
              src={vector2}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFA;
