import { useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import FormButton from "../../../components/FormComponents/FormButton/FormButton";

import Transfer from "../../../utils/apiServices/transfer";
import FormSelect from "../../../components/FormComponents/FormSelect/FormSelect";
import notify from "../../../utils/notify";
import { useSelector } from "react-redux";
import { IRootState } from "../../..";

type NGNPropType = {
  onVerify: SubmitHandler<FieldValues>;
  accountName: string;
  loading: boolean;
  setGetFee: () => void;
  proceed: boolean;
  accountNumber: number;
  setAccountNumber: (data: any) => void;
};

const NGN = ({
  accountName,
  loading,
  onVerify,
  proceed,
  setGetFee,
  accountNumber,
  setAccountNumber,
}: NGNPropType) => {
  const transfer = useSelector((state: IRootState) => state.transfer.value);
  const [banks, setBanks] = useState([]);
  const [banksLoading, setBanksLoading] = useState(true);

  useEffect(() => {
    Transfer.getBanks()
      .then((res) => {
        if (res.status) {
          setBanks(
            res.data.map(
              (entry: { BankName: string; CBNBankCode: string }) => ({
                label: entry.BankName,
                value: entry.CBNBankCode,
              })
            )
          );
          setBanksLoading(false);
        } else {
          setBanksLoading(false);
          notify("error", res.message);
        }
      })
      .catch(() => {
        setBanksLoading(false);
        notify("error", "check internet connection");
      });
  }, []);
  const { formState, handleSubmit, control } = useForm();
  const handleInputChange = (event: any) => {
    const input = event.target.value;

    // Remove any non-digit characters from the input
    const sanitizedInput = input.replace(/\D/g, "");

    // Limit the input to a maximum of 10 digits
    const limitedInput = sanitizedInput.slice(0, 10);

    setAccountNumber(limitedInput);
  };

  return (
    <form onSubmit={handleSubmit(onVerify)}>
      <div className="text-lightGreen text-base mb-2 font-black ">
        Bank Details
      </div>
      <FormSelect
        defaultInputValue={transfer.bank_name}
        defaultValue={transfer.bank_code}
        placeholder={banksLoading ? "loading..." : "Select Bank"}
        label="Bank"
        formState={formState}
        rules={{ required: "Select Bank" }}
        control={control}
        name="bank"
        options={banks}
      />
      <div className="mt-5">
        <label
          className="text-[15px] md:text-[17px] font-medium"
          htmlFor="accountnumber"
        >
          Account Number
        </label>
        <div className="relative">
          <input
            className="h-12 w-full rounded-[4px] text-[15px] md:text-base border border-gray-400 outline-none py-1 mt-2 pr-[40px] pl-5"
            type="text"
            value={accountNumber || transfer.account_number}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mt-5">
        <label
          className="text-[15px] md:text-[17px] font-medium"
          htmlFor="accountname"
        >
          Account Name
        </label>
        <div className="relative">
          <input
            className="h-12 w-full rounded-[4px] text-[15px] md:text-base border border-gray-400 outline-none py-1 mt-2 pr-[40px] pl-5"
            type="text"
            value={loading ? "loading..." : accountName || transfer.name}
            disabled
          />
        </div>
      </div>
      {!proceed && (
        <FormButton label="Verify" type="big" color="green" loading={loading} />
      )}
      {proceed && (
        <button
          className="rounded-[4px] text-white text-[14.4px] font-black cursor-pointer flex justify-center items-center mt-[30px] mb-[20px] bg-lightGreen h-11 w-full"
          onClick={setGetFee}
        >
          Proceed
        </button>
      )}
    </form>
  );
};

export default NGN;
