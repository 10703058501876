import { useState } from "react";
import OtpInput from "react-otp-input";
import { ReactComponent as Spinner } from "../../../assests/icons/spinner.svg";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../..";
import Bill from "../../../utils/apiServices/bills";
import notify from "../../../utils/notify";
import { useNavigate } from "react-router-dom";
import { resetBill } from "../reducer";

const Pin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bill = useSelector((state: IRootState) => state.bill.value);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState("");
  const handleChange = (otp: string) => {
    setOtp(otp);
  };
  const handleSubmit = () => {
    setLoading(true);
    Bill.payBill({
      amount: bill.amount,
      biller_name: bill.biller_name,
      code: bill.biller_code,
      customer: bill.customer,
      item_code: bill.product_code,
      type: "power",
      pin: otp,
    })
      .then((res) => {
        if (res.status) {
          notify("success", res.message);
          setLoading(false);
          navigate("/dashboard");
          notify("success", "Successful");
          dispatch(resetBill());
        } else {
          notify("error", res.message);
          setLoading(false);
        }
      })
      .catch(() => {
        notify("error", "Check internet connection");
        setLoading(false);
      });
  };
  return (
    <div className="flex flex-col font-black gap-2">
      <div className="flex flex-col gap-4 text-sm md:text-base p-2 bg-gray-300">
        <div className="flex items-center justify-between">
          <div className="">Meter Number:</div>
          <div className="">{bill.customer}</div>
        </div>
        <div className="flex items-center justify-between">
          <div className="">Mobile Provider:</div>
          <div className="">{bill.biller_name}</div>
        </div>{" "}
        <div className="flex items-center justify-between">
          <div className="">Plan:</div>
          <div className="">{bill.plan}</div>
        </div>
        <div className="flex items-center justify-between">
          <div className="">Amount:</div>
          <div className="text-lightGreen">NGN{bill.amount}</div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-3 mt-10">
        <div className="text-center">Enter your transaction pin below</div>
        <OtpInput
          isInputNum={true}
          value={otp}
          numInputs={4}
          onChange={handleChange}
          inputStyle={{
            width: "3rem",
            height: "3rem",
            margin: "0 1rem",
            fontSize: "16px",
            borderRadius: 4,
            color: "black",
            border: "1px solid #1EC677",
          }}
        />
        {error && <p className="text-red-500 self-center">Incorrect</p>}
      </div>
      <button
        className="flex justify-center items-center mt-10 rounded-md border-0 bg-lightGreen  text-white h-12 px-4 w-full"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading && <Spinner className="w-[30px] h-[30px] mr-2 stroke-white" />}{" "}
        Confirm
      </button>
    </div>
  );
};

export default Pin;
