/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { BillPropType } from "./types";

const initialStateValue: BillPropType = {
  amount: 0,
  biller_code: "",
  biller_name: "",
  customer: 0,
  product_code: "",
  type: "",
  name: "",
  plan: "",
};

export const billSlice = createSlice({
  name: "bill",
  initialState: { value: initialStateValue },
  reducers: {
    setBill: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
    resetBill: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setBill, resetBill } = billSlice.actions;

export default billSlice.reducer;
