/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import vector from "../../../assests/images/contact-vector.png";
import Img1 from "../../../assests/images/signup1.png";
import Img2 from "../../../assests/images/signup2.png";
import vector2 from "../../../assests/images/vecGreen.png";
import logo from "../../../assests/icons/logoDark.svg";
import back from "../../../assests/icons/back.svg";
import { ReactComponent as Spinner } from "../../../assests/icons/spinner.svg";

// Authentication
import Authentication from "../../../utils/apiServices/authentication";
import notify from "../../../utils/notify";
import { setUser } from "../../../features/profile/reducer";
type DetailsType = {
  country: string;
  number: string;
};

type TwoFAPropType = {
  set2FA: () => void;
  selectAccount: () => void;
  details: any;
};

const TwoFACode = ({ set2FA, details, selectAccount }: TwoFAPropType) => {
  const dispach = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [minutes, seconds]);

  const resendOTP = () => {
    setOtp("");
    setMinutes(1);
    setSeconds(0);
    Authentication.resendOTP({
      country_code: details.country,
      phone: details.number,
      resend: 1,
    })
      .then((res) => {
        if (res.data) {
          // console.log(res);
          notify("success", "otp resent");
        } else {
          notify("error", res.message);
        }
      })
      .catch(() => {
        notify("error", " Check your network connection");
      });
  };

  const handleChange = (otp: string) => {
    setOtp(otp);
  };

  const handleSubmit = () => {
    setLoading(true);
    // console.log(details);
    // console.log(otp);

    Authentication.signupVerify({
      phone: details.number,
      country_code: details.country,
      otp: otp,
    })
      .then((res) => {
        if (res.status) {
          const { data } = res;
          // console.log(data);
          dispach(setUser({ uuid: data.uuid }));
          selectAccount();
        } else {
          setLoading(false);
          notify("error", res.message);
        }
      })
      .catch(() => {
        notify("error", "Check your network connection");
      });
  };

  return (
    <div className="h-screen  relative">
      <div className="flex sticky top-0 z-[5] bg-white justify-between items-center text-black container mx-auto py-5 mb-10 px-4">
        <Link to="/">
          <img src={logo} alt="" className="w-28 md:w-32" />
        </Link>
        <Link className="font-medium" to="/login">
          Log in{" "}
        </Link>
      </div>
      <div className="flex container mx-auto items-center justify-around">
        <div className=" hidden lg:block self-start mt-28 relative">
          <img className="" src={Img1} alt="" />
          <img
            className="-z-[1] absolute w-[70%] -top-10 -left-8"
            src={vector}
            alt=""
          />
        </div>
        <div className="flex flex-col text-black justify-center  px-7 md:px-20 w-full md:w-[80%] lg:w-[50%]">
          <div className=" mx-auto container">
            <div
              className="flex justify-start mb-10 gap-2 items-center text-lightGreen cursor-pointer"
              onClick={() => set2FA()}
            >
              <span>
                <img src={back} alt="" />
              </span>{" "}
              Go back
            </div>
            <div className="flex flex-col items-start justify-center gap-4">
              <div className="text-[22.5px] font-black">
                Phone Number Verification
              </div>
              <div className="font-black text-lg text-lightGreen">
                Verify your phone number with a code
              </div>

              <div className="text-base pt-2">
                We sent it to {details.number}.
                <span
                  className=" text-lightGreen underline pl-1 cursor-pointer"
                  onClick={() => set2FA()}
                >
                  Change
                </span>
              </div>
            </div>
            {/*  */}
            <div className="flex flex-col items-center justify-center mt-16">
              <div className="flex flex-col justify-center items-center gap-3">
                <OtpInput
                  isInputNum={true}
                  value={otp}
                  numInputs={4}
                  onChange={handleChange}
                  inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    margin: "0 1rem",
                    fontSize: "16px",
                    borderRadius: 4,
                    color: "black",
                    border: "1px solid #1EC677",
                  }}
                />
                {error && <p className="text-red-500 self-center">Incorrect</p>}
              </div>
              <button
                className="flex justify-center items-center mt-10 rounded-md border-0 bg-lightGreen font-black text-white h-12 px-4 w-full"
                onClick={() => handleSubmit()}
              >
                {loading && (
                  <Spinner className="w-[30px] h-[30px] mr-2 stroke-white" />
                )}{" "}
                Confirm
              </button>
              <div className="text-base pt-10">
                {seconds > 0 || minutes > 0 ? (
                  <p>
                    Resend in {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <p>
                    Didn't recieve code?{" "}
                    <span
                      className="text-lightGreen underline pl-1 cursor-pointer"
                      onClick={() => resendOTP()}
                    >
                      Resend
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className=" -bottom-16 right-3 lg:relative self-end relative">
          <div className="hidden lg:block self-start mt-28 relative">
            <img className="" src={Img2} alt="" />
            <img
              className="-z-[1] absolute w-[70%] -bottom-20 -right-10"
              src={vector2}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFACode;
