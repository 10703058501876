import Footer from "../../layout/Footer/Footer";
import Navigation from "../../layout/Navigation/Navigation";

const About = () => {
  return (
    <div>
      <Navigation page="About Us" />
      <div className="pt-0 md:pt-20">About</div>
      <Footer />
    </div>
  );
};

export default About;
