import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import FormButton from "../../../components/FormComponents/FormButton/FormButton";
import FormInput from "../../../components/FormComponents/FormInput/FormInput";

import { ReactComponent as PlayDown } from "../../../assests/icons/playDown.svg";
import { setTransfer } from "../reducer";
import FormSelect from "../../../components/FormComponents/FormSelect/FormSelect";
import { IRootState } from "../../..";

type USDPropType = {
  loading: boolean;
  location: number;
  setLocation: (e: number) => void;
  onSendUSD: SubmitHandler<FieldValues>;
};

const USD = ({ location, onSendUSD, setLocation, loading }: USDPropType) => {
  const transfer = useSelector((state: IRootState) => state.transfer.value);
  const dispatch = useDispatch();
  const { register, formState, handleSubmit, control } = useForm();

  return (
    <form onSubmit={handleSubmit(onSendUSD)}>
      <div className="text-lightGreen text-base mb-2 font-black ">
        Bank Details
      </div>
      <label className="text-[17px] font-medium" htmlFor="location">
        Outside US?
      </label>
      <div className="flex mb-3 rounded-[4px] border border-gray-400 mt-2 w-full">
        <div className="relative flex items-center gap-4 py-3 pl-3 w-full ">
          <select
            className="outline-none px-2 bg-transparent text-black  w-full"
            onChange={(e) => setLocation(parseInt(e.target.value))}
            onClick={() => {
              dispatch(
                setTransfer({
                  inside_us: location,
                })
              );
            }}
          >
            <option value={0}>NO</option>
            <option value={1}>YES</option>
          </select>
          <PlayDown
            className="absolute right-6 bottom-[40%] w-3"
            fill="black"
          />
        </div>
      </div>

      <div>
        <FormInput
          label="Full Name of Account Holder"
          name="account_name"
          defaultValue={transfer.name}
          register={register}
          formState={formState}
          rules={{
            required: "Enter Account Name",
          }}
          type="text"
        />
        {location === 1 ? (
          <div className="">
            <FormInput
              label="Routing Number"
              name="routing_number"
              register={register}
              formState={formState}
              defaultValue={transfer.routing_number}
              rules={{
                required: "Enter Routing Number",
              }}
              type="text"
            />
            <FormSelect
              control={control}
              defaultValue="none"
              formState={formState}
              name="delivery_type"
              options={[
                { label: "ACH", value: "ACH" },
                { label: "Domestic Wire", value: "Domestic Wire" },
              ]}
              rules={{ required: "Select Delivery type" }}
              label="Delivery Type"
            />
          </div>
        ) : (
          <FormInput
            label="Swift Code"
            name="swift_code"
            defaultValue={transfer.swift_code}
            register={register}
            formState={formState}
            rules={{
              required: "Enter Swift Code",
            }}
            type="text"
          />
        )}
        <FormInput
          label="Account Number"
          name="account_number"
          register={register}
          defaultValue={transfer.account_number}
          formState={formState}
          rules={{
            required: "Enter Account Number",
          }}
          type="text"
        />
        <FormInput
          label="Bank Name"
          name="bankname"
          register={register}
          formState={formState}
          defaultValue={transfer.bank_name}
          rules={{
            required: "Enter Bank Name",
          }}
          type="text"
        />
        <FormInput
          label="Address"
          name="address"
          register={register}
          defaultValue={transfer.address}
          formState={formState}
          rules={{
            required: "Enter Address",
          }}
          type="text"
        />
        <FormInput
          label="City"
          name="city"
          register={register}
          formState={formState}
          defaultValue={transfer.city}
          rules={{
            required: "Enter City",
          }}
          type="text"
        />
        <FormInput
          label="Zip Code"
          name="zipcode"
          defaultValue={transfer.zipcode}
          register={register}
          formState={formState}
          rules={{
            required: "Enter Zip Code",
          }}
          type="text"
        />
        <FormInput
          label="Amount"
          name="amount"
          defaultValue={transfer.amount.toString()}
          register={register}
          formState={formState}
          rules={{
            required: "Enter Amount",
          }}
          type="number"
        />
        <FormInput
          label="Note"
          name="note"
          register={register}
          formState={formState}
          rules={{
            required: "Enter Note",
          }}
          type="text"
        />
        <FormButton label="Send" type="big" color="green" loading={loading} />
      </div>
    </form>
  );
};

export default USD;
