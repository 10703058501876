import { ReactComponent as Spinner } from "../../../assests/icons/spinner.svg";

type FormButtonProps = {
  label: string;
  type: "big" | "small";
  color: "green" | "orange";
  icon?: boolean;
  error?: boolean;
  loading: boolean;
};
const FormButton = ({
  label,
  type,
  color,
  icon,
  error,
  loading,
}: FormButtonProps) => {
  return (
    <button
      className={`rounded-[4px] text-white text-[14.4px] font-black cursor-pointer flex justify-center items-center mt-[30px] mb-[20px] ${
        color === "green" ? " bg-lightGreen" : " bg-orange-500"
      } ${type === "big" ? "h-11 w-full" : " formbutton-small"}`}
      type="submit"
      disabled={loading || error}
    >
      {loading && <Spinner className="w-[30px] h-[30px] mr-2 stroke-white" />}
      {label}
    </button>
  );
};

export default FormButton;
