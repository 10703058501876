import { useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../components/FormComponents/FormInput/FormInput";
import FormSelect from "../../../components/FormComponents/FormSelect/FormSelect";
import FormButton from "../../../components/FormComponents/FormButton/FormButton";

type ResolveRemittancePropType = {
  accounts: any;
  verifyLoading: boolean;
  onVerify: SubmitHandler<FieldValues>;
  onProceed: SubmitHandler<FieldValues>;
  proceed: boolean;
};

const ResolveUsername = ({
  onVerify,
  onProceed,
  verifyLoading,
  accounts,
  proceed,
}: ResolveRemittancePropType) => {
  const [userAccounts, setUserAccounts] = useState([]);

  useEffect(() => {
    setUserAccounts(
      accounts.map(
        (entry: { currency: string; account_number: string; id: number }) => ({
          label: `[${entry.currency}] ${entry.account_number}`,
          value: entry.id,
        })
      )
    );
  }, [accounts]);
  const { register, formState, handleSubmit, control } = useForm();
  const onhandleSubmit = () => {
    if (proceed) {
      return onProceed;
    } else {
      return onVerify;
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onhandleSubmit())} className="">
        <FormInput
          formState={formState}
          label="Username"
          name="username"
          register={register}
          rules={{ required: "Enter username" }}
          type="text"
          placeholder="username here"
        />
        {accounts.length !== 0 && (
          <div className="">
            <FormSelect
              placeholder={verifyLoading ? "loading..." : "Select account"}
              label="Recipient account"
              formState={formState}
              rules={{ required: "Select account" }}
              control={control}
              name="account"
              bottomLabel={accounts[0].account_name}
              options={userAccounts}
            />
          </div>
        )}
        <FormButton
          loading={verifyLoading}
          color="green"
          label={proceed ? "proceed" : "verify"}
          type="big"
        />
      </form>
    </div>
  );
};

export default ResolveUsername;
