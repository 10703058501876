import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assests/icons/logoDark.svg";
import back from "../../../assests/icons/back.svg";
import { setBank } from "../../../features/dashbard/reducer";

type KYCDonePropType = {
  setUpload: (data?: any) => void;
};
const KYCDone = ({ setUpload }: KYCDonePropType) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const close = () => {
    dispatch(setBank({ popup: true }));
    navigate("/dashboard");
  };
  return (
    <div className="h-screen flex flex-col justify-center items-center md:block relative">
      <div className="flex fixed md:sticky top-0 z-[5] bg-white justify-between items-center text-black container mx-auto py-5 mb-10 px-4">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
        <div
          className="flex justify-start gap-2 items-center text-lightGreen cursor-pointer"
          onClick={() => setUpload()}
        >
          <span>
            <img src={back} alt="" />
          </span>{" "}
          Go back
        </div>
      </div>
      <div className="flex container mx-auto items-center justify-around">
        <div className="text-black  px-7 md:px-20 w-full md:w-[80%] lg:w-[45%]">
          <div className=" mx-auto container flex flex-col justify-center items-center">
            <div className="text-[22.5px] font-black">KYC In Review</div>
            <div className="flex text-base pt-2 text-center">
              We've received your information and reviewing your documents.
              We'll notify you once your account has bee verified
            </div>
          </div>
          <button
            className="flex justify-center items-center mt-10 rounded-md border-0 bg-lightGreen font-black text-white h-12 px-4 w-full"
            onClick={close}
          >
            Okay, Go to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default KYCDone;
