/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { BeneficiaryType } from "./types";

const initialStateValue: BeneficiaryType = {
  beneficiary: [],
  beneficiaryLoading: true,
};

export const beneficiarySlice = createSlice({
  name: "beneficiary",
  initialState: { value: initialStateValue },
  reducers: {
    setBeneficiary: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetBeneficiary: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setBeneficiary, resetBeneficiary } = beneficiarySlice.actions;

export default beneficiarySlice.reducer;
