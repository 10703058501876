import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler } from "react-hook-form";

import logo from "../../../assests/icons/logoDark.svg";
import back from "../../../assests/icons/back.svg";
import ResolveAirtime from "./ResolveAirtime";

import Bills from "../../../utils/apiServices/bills";
import notify from "../../../utils/notify";
import { useDispatch } from "react-redux";
import { setBill } from "../reducer";
import Pin from "./Pin";

const Airtime = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [networks, setNetworks] = useState<any>([]);
  const [networkLoading, setNetworkLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState("resolve");

  useEffect(() => {
    setNetworkLoading(true);
    Bills.getBillers({ type: "airtime" })
      .then((res) => {
        if (res.status) {
          setNetworkLoading(false);
          setNetworks(res.data);
        } else {
          notify("error", res.message);
          setNetworkLoading(false);
        }
      })
      .catch(() => {
        setNetworkLoading(false);
        notify("error", "check internet connection");
      });
  }, []);

  const setResolve = () => {
    setCurrentPage("resolve");
    setNetworks([]);
  };

  const displayPage = () => {
    switch (currentPage) {
      case "resolve":
        return (
          <ResolveAirtime
            netWorkLoading={networkLoading}
            loading={loading}
            mobileNetwork={networks}
            onProceed={onValidate}
          />
        );

      case "pin":
        return <Pin />;

      default:
        break;
    }
  };
  const onValidate: SubmitHandler<FieldValues> = (formData) => {
    setLoading(true);
    Bills.validateCusomer({
      code: formData.network.value.split(" ")[0],
      customer: formData.number,
      item_code: formData.network.value.split(" ")[1],
    })
      .then((res) => {
        if (res.status) {
          setLoading(false);
          const { data } = res;
          dispatch(
            setBill({
              amount: formData.amount,
              biller_code: data.biller_code,
              biller_name: formData.network.value.split(" ")[2],
              customer: data.customer,
              product_code: data.product_code,
              type: "airtime",
            })
          );
          setCurrentPage("pin");
        } else {
          setLoading(false);
          notify("error", res.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "Check internet connection");
      });
  };
  return (
    <div className="mb-10">
      <div className="flex sticky top-0 z-[5] bg-white justify-between items-center text-black container mx-auto py-5 mb-10 px-4 ">
        <Link to="/dashboard">
          <img src={logo} alt="" className="w-28 md:w-32" />
        </Link>
        <div
          className="flex justify-start gap-2 items-center text-lightGreen cursor-pointer text-sm md:text-base"
          onClick={() => (currentPage === "pin" ? setResolve() : navigate(-1))}
        >
          <span>
            <img src={back} alt="" />
          </span>{" "}
          Go back
        </div>
      </div>
      <div className="flex container mx-auto items-center justify-around bg-red">
        <div className=" flex flex-col text-black justify-center px-4 md:px-20 w-full md:w-[80%] lg:w-[55%] xl:[45%]">
          {displayPage()}
        </div>
      </div>
    </div>
  );
};

export default Airtime;
