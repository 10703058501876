import { FieldValues, FormState, UseFormRegister } from "react-hook-form";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./FormPhoneNumber.css";

type FormPhoneNumberPropType = {
  label: string;
  subLabel?: string;
  bottomLabel?: string;
  register: UseFormRegister<FieldValues>;
  name: string;
  placeholder?: string;
  formState: FormState<FieldValues>;
  rules: any;
  disabled?: boolean;
  value?: string;
  onChange: () => void;
  defaultCountry: any;
};

const FormPhoneNumber = ({
  label,
  defaultCountry,
  subLabel,
  bottomLabel,
  formState,
  name,
  register,
  rules,
  disabled,
  placeholder,
  onChange,
  value,
}: FormPhoneNumberPropType) => {
  // const [value, setValue] = useState();

  return (
    <div className="mt-8">
      <label className="text-[17px] font-medium" htmlFor={name}>
        {label}
      </label>
      {subLabel && <p className="text-[#B8B8B8] text-xs">{subLabel}</p>}
      <PhoneInput
        className="phone"
        defaultCountry={defaultCountry}
        placeholder={placeholder}
        countries={["NG"]}
        addInternationalOption={false}
        disabled={disabled}
        {...register(name, rules)}
        value={value}
        onChange={onChange}
      />
      {bottomLabel && <p>{bottomLabel}</p>}
      {formState.errors[name] && (
        <p className="text-red-500">
          {formState.errors[name]?.message?.toString()}
        </p>
      )}
    </div>
  );
};

export default FormPhoneNumber;
