/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { BankPropTypes } from "./types";

const initialStateValue: BankPropTypes = {
  allBanks: [],
  name: "",
  detailedBank: [],
  popup: false,
  allBanksLoading: true,
  rates: {
    ghs: "",
    usd: "",
  },
};

export const bankSlice = createSlice({
  name: "bank",
  initialState: { value: initialStateValue },
  reducers: {
    setBank: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setBank } = bankSlice.actions;

export default bankSlice.reducer;
