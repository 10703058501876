/* eslint-disable @typescript-eslint/no-unused-vars */

import { Link } from "react-router-dom";
import vector from "../../../assests/images/contact-vector.png";
import Img1 from "../../../assests/images/signup1.png";
import Img2 from "../../../assests/images/signup2.png";
import vector2 from "../../../assests/images/vecGreen.png";
import logo from "../../../assests/icons/logoDark.svg";
import personal from "../../../assests/icons/personal.svg";
import business from "../../../assests/icons/business.svg";
import back from "../../../assests/icons/back.svg";

type DetailsType = {
  country: string;
  number: string;
};
type AccountSelectionPropType = {
  bactToForm: (account: string) => void;
  set2FA: () => void;
};

const AccountSelection = ({ bactToForm, set2FA }: AccountSelectionPropType) => {
  return (
    <div className="h-screen  relative">
      <div className="flex sticky top-0 z-[5] bg-white justify-between items-center text-black container mx-auto py-5 mb-10 px-4">
        <Link to="/">
          <img src={logo} alt="" className="w-28 md:w-32" />
        </Link>
        <Link className="font-medium" to="/login">
          Log in{" "}
        </Link>
      </div>
      <div className="flex container mx-auto items-center justify-around">
        <div className=" hidden lg:block self-start mt-28 relative">
          <img className="" src={Img1} alt="" />
          <img
            className="-z-[1] absolute w-[70%] -top-10 -left-8"
            src={vector}
            alt=""
          />
        </div>
        <div className="flex flex-col text-black justify-center px-7 md:px-16 w-full md:w-[80%] lg:w-[50%]">
          <div className="flex flex-col gap-3 mx-auto container">
            <div
              className="flex justify-start mb-12 gap-2 items-center text-lightGreen cursor-pointer"
              onClick={() => set2FA()}
            >
              <span>
                <img src={back} alt="" />
              </span>{" "}
              Go back
            </div>
            <div className="text-[22.5px] font-black">Account Type</div>
            <div className="font-black text-lg text-lightGreen">
              Would you like to start a particular type of account today?
            </div>
            <div className="flex text-base pt-2">
              You can add another later on too.
            </div>
            {/*  */}
            <div className="flex gap-5 mt-16 ">
              <div
                className="flex flex-col justify-center items-center bg-[#F7FAF8] p-2 md:p-5 py-8 gap-3 w-1/2 cursor-pointer hover:border-2 border-lightGreen"
                onClick={() => bactToForm("personal")}
              >
                <img src={personal} alt="" />
                <div className="text-black text-lg font-black text-center">
                  Personal account
                </div>
                <div className="font-normal text-black text-xs text-center">
                  Send, spend and receive money around the world for less
                </div>
              </div>
              <div
                className="flex flex-col justify-center items-center bg-[#F7FAF8] p-2 md:p-5 py-8 gap-3 w-1/2 cursor-pointer hover:border-2 border-lightGreen"
                onClick={() => bactToForm("business")}
              >
                <img src={business} alt="" />
                <div className="text-black text-lg font-black text-center">
                  Business account
                </div>
                <div className="font-normal text-black text-xs text-center">
                  Do business or freelance work internationally
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" -bottom-16 right-3 lg:relative self-end relative">
          <div className="hidden lg:block self-start mt-28 relative">
            <img className="" src={Img2} alt="" />
            <img
              className="-z-[1] absolute w-[70%] -bottom-20 -right-12"
              src={vector2}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSelection;
