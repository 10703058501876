import React, { useState } from "react";
import OtpInput from "react-otp-input";

import { ReactComponent as Spinner } from "../../../assests/icons/spinner.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

type PropType = {
  onNext: () => void;
  setPin: (pin: string) => void;
};

const TransferPin = ({ onNext, setPin }: PropType) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [otp, setOtp] = useState("");
  const handleChange = (otp: string) => {
    setOtp(otp);
  };

  const handleSubmit = () => {
    if (otp !== "" && otp.length === 4) {
      onNext();
      setPin(otp);
    }
    setError(true);
    setErrorMessage("Enter Valid OTP");
  };
  return (
    <div className="w-full bg-white flex justify-center">
      <div className=" flex flex-col text-black justify-center px-7 md:px-20 w-full md:w-[80%] lg:w-[50%]">
        <div className=" mx-auto container">
          <div className="text-[22.5px] font-black">Set Transaction Pin</div>
          <div className="flex text-sm md:text-base pt-2">
            Kindly set a transactions pin to authorize transactions. This PIN
            will be requested when you want to complete any transaction.
          </div>
          <div className="flex flex-col justify-center items-center gap-3 mt-10">
            <OtpInput
              isInputNum={true}
              value={otp}
              numInputs={4}
              onChange={handleChange}
              inputStyle={{
                width: "3rem",
                height: "3rem",
                margin: "0 1rem",
                fontSize: "16px",
                borderRadius: 4,
                color: "black",
                border: "1px solid #1EC677",
              }}
            />
            {error && (
              <p className="text-red-500 text-sm self-center">{errorMessage}</p>
            )}
          </div>
          <button
            className="flex justify-center items-center mt-10 rounded-md border-0 bg-lightGreen font-black text-white h-12 px-4 w-full"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading && (
              <Spinner className="w-[30px] h-[30px] mr-2 stroke-white" />
            )}{" "}
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransferPin;
