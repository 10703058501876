import Blog1 from "../../assests/images/blog1.png";
import a from "../../assests/images/a.png";
import b from "../../assests/images/b.png";

export const blogPost = [
  {
    blogId: "1",
    title: "Lorem Ipsum",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rhoncus, lorem vel pretium viverra, augue felis cur sus enim, at cursus dui elit sit amet justo.",
    exert:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rhoncus, lorem vel pretium viverra, augue felis cursus enim, at cursus dui elit sit amet justo.",
    img: Blog1,
  },
  {
    blogId: "2",
    title: "Lorem Ipsum",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rhoncus, lorem vel pretium viverra, augue felis cur sus enim, at cursus dui elit sit amet justo.",
    exert:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rhoncus, lorem vel pretium viverra, augue felis cursus enim, at cursus dui elit sit amet justo.",
    img: a,
  },
  {
    blogId: "3",
    title: "Lorem Ipsum",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rhoncus, lorem vel pretium viverra, augue felis cur sus enim, at cursus dui elit sit amet justo.",
    exert:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rhoncus, lorem vel pretium viverra, augue felis cursus enim, at cursus dui elit sit amet justo.",
    img: b,
  },
  {
    blogId: "4",
    title: "Lorem Ipsum",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rhoncus, lorem vel pretium viverra, augue felis cur sus enim, at cursus dui elit sit amet justo.",
    exert:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rhoncus, lorem vel pretium viverra, augue felis cursus enim, at cursus dui elit sit amet justo.",
    img: a,
  },
  {
    blogId: "5",
    title: "Lorem Ipsum",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rhoncus, lorem vel pretium viverra, augue felis cur sus enim, at cursus dui elit sit amet justo.",
    exert:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rhoncus, lorem vel pretium viverra, augue felis cursus enim, at cursus dui elit sit amet justo.",
    img: b,
  },
];
