import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import FormInput from "../../../components/FormComponents/FormInput/FormInput";
import FormButton from "../../../components/FormComponents/FormButton/FormButton";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../..";
import { useState } from "react";
import { setTransfer } from "../reducer";

type ResolveEnairaPropType = {
  onVerify: SubmitHandler<FieldValues>;
  name: string;
  loading: boolean;
  setGetFee: () => void;
  proceed: boolean;
};

const ResolveEnaira = ({
  loading,
  name,
  onVerify,
  proceed,
  setGetFee,
}: ResolveEnairaPropType) => {
  const transfer = useSelector((state: IRootState) => state.transfer.value);
  const { register, formState, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const [beneficiary, setBeneficiary] = useState(false);
  const onSetBeneficiary = (e: any) => {
    setBeneficiary(e.target.checked);
    dispatch(
      setTransfer({
        save_beneficiary: e.target.checked,
      })
    );
  };
  return (
    <form onSubmit={handleSubmit(onVerify)}>
      <FormInput
        label="Alias"
        name="alias"
        defaultValue={transfer.alias}
        register={register}
        formState={formState}
        rules={{
          required: "Enter Alias",
        }}
        type="text"
      />
      {/* {loading ? "loading..." : accountName} */}
      {transfer.save_beneficiary && (
        <div className="flex items-center gap-2 mt-4 text-[15px] md:text-base">
          <input
            type="checkbox"
            name=""
            id=""
            checked={beneficiary}
            onChange={onSetBeneficiary}
          />
          <div className="">Save as beneficiary</div>
        </div>
      )}
      {name && <div className="mt-3 font-black">{name}</div>}
      {!proceed && (
        <FormButton label="Verify" type="big" color="green" loading={loading} />
      )}
      {proceed && (
        <button
          className="rounded-[4px] text-white text-[14.4px] font-black cursor-pointer flex justify-center items-center mt-[30px] mb-[20px] bg-lightGreen h-11 w-full"
          onClick={setGetFee}
        >
          Proceed
        </button>
      )}
    </form>
  );
};

export default ResolveEnaira;
