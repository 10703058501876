import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../..";

import { ReactComponent as Spinner } from "../../../assests/icons/spinner.svg";
import { setTransfer } from "../reducer";

type SendPagePropType = {
  onSend: () => void;
  loading: boolean;
};

const SendPage = ({ onSend, loading }: SendPagePropType) => {
  const dispatch = useDispatch();
  const user = useSelector((state: IRootState) => state.transfer.value);
  const [beneficiary, setBeneficiary] = useState(false);
  const onSetBeneficiary = (e: any) => {
    setBeneficiary(e.target.checked);
    dispatch(
      setTransfer({
        save_beneficiary: e.target.checked,
      })
    );
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="text-black font-black text-xl">
        Review details of your transfer
      </div>
      <div>Transfer</div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">You send exactly</div>
        <div className="font-bold md:text-base text-sm">{user.amount} NGN</div>
      </div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">Total fees (included)</div>
        <div className="font-bold md:text-base text-sm">{user.fee} NGN</div>
      </div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">
          Total amount we'll deduct
        </div>
        <div className="font-bold md:text-base text-sm">
          {user.amount + user.fee} NGN
        </div>
      </div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">Recipient gets</div>
        <div className="font-bold md:text-base text-sm">{user.amount} NGN</div>
      </div>
      <div className="mt-4">Recipient Details</div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">Name</div>
        <div className="font-bold md:text-base text-sm w-[60%] md:w-full text-right">
          {user.name}
        </div>
      </div>
      {user.alias && (
        <div className="flex justify-between">
          <div className="text-[#75757575] text-sm">Alias</div>
          <div className="font-bold md:text-base text-sm ">{user.alias}</div>
        </div>
      )}
      {user.account_number && (
        <div className="flex justify-between">
          <div className="text-[#75757575] text-sm ">Account Number</div>
          <div className="font-bold md:text-base text-sm">
            {user.account_number}
          </div>
        </div>
      )}
      <div className="mt-4">Description</div>
      <textarea
        className=" w-full rounded-[4px] border border-gray-400 outline-none  mt-1 p-5"
        style={{ resize: "none" }}
        rows={3}
        disabled
        value={user ? user.note : ""}
      ></textarea>
      {user.save_beneficiary && (
        <div className="flex items-center gap-2 mt-4 text-[15px] md:text-base">
          <input
            type="checkbox"
            name=""
            id=""
            checked={beneficiary}
            onChange={onSetBeneficiary}
          />
          <div className="">Save as beneficiary</div>
        </div>
      )}

      <button
        className="flex justify-center items-center mt-10 rounded-md border-0 bg-lightGreen font-black text-white h-12 px-4 w-full"
        onClick={onSend}
      >
        {loading && <Spinner className="w-[30px] h-[30px] mr-2 stroke-white" />}{" "}
        Confirm and Send
      </button>
    </div>
  );
};

export default SendPage;
