import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../components/FormComponents/FormInput/FormInput";
import FormButton from "../../../components/FormComponents/FormButton/FormButton";
import logo from "../../../assests/icons/logoDark.svg";
import back from "../../../assests/icons/back.svg";
// import FormPhoneNumber from "../../../components/FormComponents/FormPhoneNumber/FormPhoneNumber";
import FormCountrySelect from "../../../components/FormComponents/FormCountrySelect/FormCountrySelect";
import { useDispatch, useSelector } from "react-redux";
import { setKyc } from "../reducer";
import { IRootState } from "../../..";
type KYCFormPropType = {
  setUpload: (data: any) => void;
};

const KYCForm = ({ setUpload }: KYCFormPropType) => {
  const user = useSelector((state: IRootState) => state.user.value);
  const kyc = useSelector((state: IRootState) => state.kyc.value);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { register, control, formState, handleSubmit, watch } = useForm();

  const onSignUp: SubmitHandler<FieldValues> = (formData) => {
    // setLoading(true);
    dispatch(
      setKyc({
        address: formData.address,
        bvn: formData.bvn,
        city: formData.city,
        country: formData.country.label,
        dob: formData.dob,
        firstname: formData.firstname,
        lastname: formData.lastname,
        middlename: formData.middlename,
        poatalcode: formData.postalcode,
        state: formData.state,
        business_name: formData.business_name,
        registration_number: formData.registration_number,
        tin: formData.tin,
      })
    );
    setUpload(formData);

    // setTimeout(() => {
    //   setLoading(false);
    //   setUpload(formData);
    // }, 1000);
  };
  const watchPassword = useRef({});
  watchPassword.current = watch("password", "");
  return (
    <div className="mb-10">
      <div className="flex sticky top-0 z-[5] bg-white justify-between items-center text-black container mx-auto py-5 mb-10 px-4">
        <Link to="/dashboard">
          <img src={logo} alt="" />
        </Link>
        <div
          className="flex justify-start gap-2 items-center text-lightGreen cursor-pointer"
          onClick={() => navigate("/dashboard")}
        >
          <span>
            <img src={back} alt="" />
          </span>{" "}
          Go back
        </div>
      </div>
      <div className="flex container mx-auto items-center justify-around">
        <div className=" flex flex-col text-black justify-center px-7 md:px-20 w-full md:w-[80%] lg:w-[55%] xl:[45%]">
          <div className=" mx-auto container">
            <div className="text-[22.5px] font-black">
              Identity Verification
            </div>
            <div className="flex text-base pt-2">Tell us about yourself</div>
            <form onSubmit={handleSubmit(onSignUp)}>
              <FormInput
                label="First name"
                defaultValue={kyc.firstname || ""}
                type="text"
                register={register}
                name="firstname"
                rules={{ required: "Enter your Name" }}
                formState={formState}
                placeholder="First name"
              />
              <FormInput
                label="Middle name"
                defaultValue={kyc.middlename || ""}
                type="text"
                register={register}
                name="middlename"
                rules={{}}
                formState={formState}
                placeholder="Middle name"
              />
              <FormInput
                defaultValue={kyc.lastname || ""}
                label="Full legal last name(s)"
                type="text"
                register={register}
                name="lastname"
                rules={{ required: "Enter your Name" }}
                formState={formState}
                placeholder="Full legal last name(s)"
              />
              {user.type.toLowerCase() === "business" && (
                <div className="">
                  <FormInput
                    defaultValue={kyc.business_name || ""}
                    label="Business name"
                    type="text"
                    register={register}
                    name="business_name"
                    rules={{ required: "Enter your business name" }}
                    formState={formState}
                    placeholder="Business name"
                  />
                  <FormInput
                    defaultValue={kyc.registration_number || ""}
                    label="Registeration number"
                    type="text"
                    register={register}
                    name="registration_number"
                    rules={{ required: "Enter your Registeration number" }}
                    formState={formState}
                    placeholder="Registeration number"
                  />
                  <FormInput
                    defaultValue={kyc.tin || ""}
                    label="Tin"
                    type="text"
                    register={register}
                    name="tin"
                    rules={{ required: "Enter your Tin" }}
                    formState={formState}
                    placeholder="Tin"
                  />
                </div>
              )}
              <FormInput
                label="Date of birth"
                type="date"
                defaultValue={kyc.dob || ""}
                register={register}
                name="dob"
                rules={{ required: "Enter DOB" }}
                formState={formState}
                placeholder="Select Date"
              />
              <FormInput
                label="Bank Verification Number (BVN)"
                defaultValue={kyc.bvn.toString() || ""}
                type="number"
                register={register}
                name="bvn"
                rules={{ required: "Enter your BVN" }}
                formState={formState}
                placeholder="Enter your BVN here"
              />
              <FormCountrySelect
                label="Select country of residence"
                control={control}
                formState={formState}
                name="country"
                rules={{ required: "Select Country" }}
              />
              <FormInput
                defaultValue={kyc.state || ""}
                label="Enter your State"
                placeholder="State"
                type="text"
                register={register}
                name="state"
                rules={{ required: "Enter your State" }}
                formState={formState}
              />

              {/* <FormCountrySelect
                label="Select state"
                control={control}
                formState={formState}
                name="state"
                rules={{ required: "Select state" }}
              /> */}
              <FormInput
                label="Home Address"
                type="text"
                defaultValue={kyc.address || ""}
                register={register}
                name="address"
                rules={{ required: "Enter your Address" }}
                formState={formState}
                placeholder="Home address"
              />
              <FormInput
                label="City"
                defaultValue={kyc.city || ""}
                type="text"
                register={register}
                name="city"
                rules={{ required: "Enter your City" }}
                formState={formState}
                placeholder="City"
              />
              <FormInput
                label="Postal Code"
                type="number"
                defaultValue={kyc.postal_code || ""}
                register={register}
                name="poatalcode"
                rules={{ required: "Enter your Postal code" }}
                formState={formState}
                placeholder="Postal code"
              />
              <FormButton
                label="Proceed"
                type="big"
                color="green"
                // error={formState.errors.terms_and_conditions}
                loading={loading}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KYCForm;
