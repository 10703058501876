import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../components/FormComponents/FormInput/FormInput";
import FormButton from "../../../components/FormComponents/FormButton/FormButton";
import { useSelector } from "react-redux";
import { IRootState } from "../../..";
import FormTextField from "../../../components/FormComponents/FormTextField/FormTextField";
type AmountPagePropType = {
  onProceed: SubmitHandler<FieldValues>;
  username: string;
};
const AmountPage = ({ username, onProceed }: AmountPagePropType) => {
  const transfer = useSelector((state: IRootState) => state.transfer.value);
  const { register, formState, handleSubmit } = useForm();
  return (
    <div>
      <form onSubmit={handleSubmit(onProceed)}>
        <FormInput
          formState={formState}
          label="Username"
          name="username"
          disabled
          defaultValue={username || transfer.username}
          register={register}
          rules={{}}
          type="text"
          placeholder="username here"
        />
        <FormInput
          formState={formState}
          label="Amount"
          defaultValue={transfer.amount.toString()}
          name="amount"
          register={register}
          rules={{ required: "Enter amount" }}
          type="number"
          placeholder="1000"
        />

        <FormTextField
          formState={formState}
          label="Description"
          defaultValue={transfer.note}
          name="description"
          register={register}
          rules={{ required: "Enter description" }}
          placeholder="Transaction Descriptuon"
        />

        <FormButton
          loading={false}
          color="green"
          label={"Proceed"}
          type="big"
        />
      </form>
    </div>
  );
};

export default AmountPage;
