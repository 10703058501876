import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assests/icons/logoDark.svg";
import back from "../../../assests/icons/back.svg";

import Transfer from "../../../utils/apiServices/transfer";
import { SubmitHandler, FieldValues } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../..";
import notify from "../../../utils/notify";
import { setTransfer } from "../reducer";
import ResolveMomo from "./ResolveMomo";
import GetMomoFee from "./GetMomoFee";
import MomoPin from "./Pin";
import SendMomoPage from "./SendMomoPage";

const MomoPage = () => {
  const user = useSelector((state: IRootState) => state.transfer.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState("ghs" || user.currency);
  const [location, setLoacation] = useState<number>(0);
  const [accountName, setAccountName] = useState(user.name || null);
  const [mobileNumber, setMobileNumber] = useState(user.account_number || "");

  const [currentPage, setCurrentPage] = useState<
    "resolve" | "getfee" | "send" | "pin"
  >("resolve");

  const setResolve = () => {
    setAccountName(null);
    setCurrentPage("resolve");
  };
  const onSetGetFee: SubmitHandler<FieldValues> = (formData) => {
    dispatch(
      setTransfer({
        currency: currency,
        account_number: mobileNumber,
        issuer: formData.issuer.value || user.issuer,
        name: accountName,
      })
    );
    setCurrentPage("getfee");
  };

  const displayPage = () => {
    switch (currentPage) {
      case "resolve":
        return (
          <ResolveMomo
            setAccountName={setAccountName}
            setGetFee={onSetGetFee}
            proceed={user.proceed}
            loading={loading}
            accountName={accountName || ""}
            currency={currency}
            setCurrency={setCurrency}
            location={location}
            setLocation={setLoacation}
            mobileNumber={mobileNumber}
            setMobileNumber={setMobileNumber}
          />
        );
      case "getfee":
        return <GetMomoFee loading={loading} onVerify={onGetMomoFee} />;
      case "send":
        return (
          <SendMomoPage
            onSend={() => setCurrentPage("pin")}
            loading={loading}
          />
        );
      case "pin":
        return <MomoPin currency={currency} />;

      default:
        return "";
    }
  };

  // Get Fee
  const onGetMomoFee: SubmitHandler<FieldValues> = (formData) => {
    setLoading(true);
    Transfer.getMomoFee({
      currency: user.currency,
      number: user.account_number,
      amount: formData.amount,
    })
      .then((res) => {
        if (res.status) {
          const { data } = res;

          dispatch(
            setTransfer({
              amount: parseInt(formData.amount),
              fee: data.fee,
              note: formData.description,
              issuer: user.issuer,
            })
          );
          setLoading(false);
          setCurrentPage("send");
        } else {
          setLoading(false);
          notify("error", res.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "check your internet connection");
      });
  };

  return (
    <div className="mb-10">
      <div className="flex sticky top-0 z-[5] bg-white justify-between items-center text-black container mx-auto py-5 mb-10 px-4 ">
        <Link to="/dashboard">
          <img src={logo} alt="" className="w-28 md:w-32" />
        </Link>
        <div
          className="flex justify-start gap-2 items-center text-lightGreen cursor-pointer text-sm md:text-base"
          onClick={() =>
            currentPage === "getfee"
              ? setResolve()
              : currentPage === "send"
              ? setCurrentPage("getfee")
              : currentPage === "pin"
              ? setCurrentPage("send")
              : navigate(-1)
          }
        >
          <span>
            <img src={back} alt="" />
          </span>{" "}
          Go back
        </div>
      </div>
      <div className="flex container mx-auto items-center justify-around bg-red">
        <div className=" flex flex-col text-black justify-center px-4 md:px-20 w-full md:w-[80%] lg:w-[55%] xl:[45%]">
          {displayPage()}
        </div>
      </div>
    </div>
  );
};

export default MomoPage;
