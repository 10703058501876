/* eslint-disable react/jsx-props-no-spreading */
import { Control, Controller, FieldValues, FormState } from "react-hook-form";
import Select from "react-select";
import "./FormSelect.css";

type FormSelectProps = {
  label?: string;
  subLabel?: string;
  bottomLabel?: string;
  control: Control<FieldValues, any> | undefined;
  name: string;
  placeholder?: string;
  options: {
    label: any;
    value: any;
  }[];
  formState: FormState<FieldValues>;
  rules: any;
  disabled?: boolean;
  multi?: boolean;
  defaultInputValue?: string;
  defaultValue?: string;
  onChange?: (value: any) => void;
};

function FormSelect({
  label,
  subLabel, // optional
  bottomLabel, // optional
  control,
  name,
  placeholder, // optional
  options,
  formState,
  rules,
  disabled,
  multi,
  defaultValue,
  defaultInputValue,
  onChange: customOnChange,
}: FormSelectProps) {
  return (
    <div className="FormSelect FormInput">
      <label className="text-[15px] md:text-[17px]" htmlFor={name}>
        {label}
      </label>
      {subLabel && <p className="subLabel">{subLabel}</p>}
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            options={options}
            onChange={(value) => {
              onChange(value);
              if (customOnChange) {
                customOnChange(value); // Call customOnChange if provided
              }
            }}
            value={value}
            onBlur={onBlur}
            placeholder={placeholder}
            isDisabled={disabled}
            isMulti={multi}
            defaultValue={defaultValue}
            defaultInputValue={defaultInputValue}
          />
        )}
      />
      {bottomLabel && <p className="bottom-label">{bottomLabel}</p>}
      {formState.errors[name] && (
        <p className="text-red-500">
          {formState.errors[name]?.message?.toString()}
        </p>
      )}
    </div>
  );
}

export default FormSelect;
