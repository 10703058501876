import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import FormInput from "../../../components/FormComponents/FormInput/FormInput";
import FormButton from "../../../components/FormComponents/FormButton/FormButton";
import { useSelector } from "react-redux";
import { IRootState } from "../../..";

type ResolveEnairaPropType = {
  onVerify: SubmitHandler<FieldValues>;
  loading: boolean;
};

const GetFee = ({ loading, onVerify }: ResolveEnairaPropType) => {
  const user = useSelector((state: IRootState) => state.transfer.value);
  const { register, formState, handleSubmit } = useForm();
  return (
    <form onSubmit={handleSubmit(onVerify)}>
      {/* <FormInput
        label="Alias"
        name="alias"
        register={register}
        formState={`${user.alias}`}
        disabled
        placeholder={user.alias}
        rules={{
          required: "Enter Alias",
        }}
        type="text"
      /> */}
      <div className="mt-8">
        <label className="text-[17px] font-medium" htmlFor="alias">
          Alias
        </label>
        <div className="relative">
          <input
            className="h-12 w-full rounded-[4px] border border-gray-400 outline-none py-1 mt-2 pr-[40px] pl-5"
            type="text"
            value={user ? user.alias : ""}
            disabled
          />
        </div>
      </div>
      <FormInput
        label="Amount"
        name="amount"
        register={register}
        formState={formState}
        rules={{
          required: "Enter Amount",
        }}
        type="number"
      />
      <FormInput
        label="Description"
        name="description"
        register={register}
        formState={formState}
        rules={{
          required: "Enter Description",
        }}
        type="text"
      />
      <FormButton label="Proceed" type="big" color="green" loading={loading} />
    </form>
  );
};

export default GetFee;
