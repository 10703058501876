import { useSelector } from "react-redux";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import FormTextField from "../../../components/FormComponents/FormTextField/FormTextField";
import FormButton from "../../../components/FormComponents/FormButton/FormButton";

import { IRootState } from "../../..";

type ReviewPropType = {
  onProceed: SubmitHandler<FieldValues>;
};

const Review = ({ onProceed }: ReviewPropType) => {
  const { register, formState, handleSubmit } = useForm();

  const transfer = useSelector((state: IRootState) => state.transfer.value);
  return (
    <form onSubmit={handleSubmit(onProceed)} className="flex flex-col gap-3">
      <div className="text-black font-black text-xl">
        Review details of your transfer
      </div>
      <div>Transfer</div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">You send exactly</div>
        <div className="font-bold md:text-base text-sm">
          {transfer.amount} {transfer.currency}
        </div>
      </div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">Total fees (included)</div>
        <div className="font-bold md:text-base text-sm">
          {transfer.fee} {transfer.currency}
        </div>
      </div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">
          Total amount we'll deduct
        </div>
        <div className="font-bold md:text-base text-sm">
          {transfer.amount + transfer.fee} {transfer.currency}
        </div>
      </div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">Recipient gets</div>
        <div className="font-bold md:text-base text-sm">
          {transfer.amount} {transfer.currency}
        </div>
      </div>
      <div className="mt-4">Recipient Details</div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">Name</div>
        <div className="font-bold md:text-base text-sm w-[60%] md:w-full text-right">
          {transfer.name}
        </div>
      </div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm">Username</div>
        <div className="font-bold md:text-base text-sm w-[60%] md:w-full text-right">
          {transfer.username}
        </div>
      </div>
      <div className="flex justify-between">
        <div className="text-[#75757575] text-sm whitespace-nowrap">
          Bank Account
        </div>
        <div className="font-bold md:text-base text-sm w-[60%] md:w-full text-right">
          {transfer.account_number}
        </div>
      </div>
      <FormTextField
        formState={formState}
        label="Description"
        name="description"
        defaultValue={transfer.note}
        disabled
        register={register}
        rules={{}}
      />

      <FormButton loading={false} color="green" label={"Proceed"} type="big" />
    </form>
  );
};

export default Review;
