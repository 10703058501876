// import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Navigation from "../../layout/Navigation/Navigation";
import Footer from "../../layout/Footer/Footer";

import { blogPost } from "./blogData";

const BlogDetails = () => {
  const navigate = useNavigate();
  let { blogId } = useParams();

  const blog = blogPost.find((b) => b.blogId === blogId);

  const filter = blogPost.filter((item) => item.blogId !== blogId);
  //   useEffect(() => {
  //     console.log("THis", blogId);
  //   }, []);
  return (
    <div>
      <Navigation page="BlogID" />
      <div className="relative pt-16 md:pt-16">
        <img
          src={blog?.img}
          alt=""
          className="w-full md:h-[500px] h-[300px] object-cover"
        />
        <div>
          <div className="absolute bottom-0 left-0 flex flex-col gap-4 md:w-[70%] p-3 md:p-5 text-white">
            <div className=" text-lg md:text-xl font-black">
              {blog?.title || "Nothing"}
            </div>
            <div className="text-sm md:text-lg font-normal">
              {blog?.exert || "Nothing"}
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 my-5 text-black border-b-2">
        {blog?.exert || "Nothing"}
      </div>

      <div className="bg-white">
        <div className="container mx-auto my-10">
          <div className="flex flex-col justify-center items-center ">
            {/* <div className="flex flex-col md:flex-row  md:gap-10 md:p-10"> */}
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10 md:p-24">
              {filter.map((blog) => (
                <div
                  key={blog.blogId}
                  className="flex flex-col justify-center items-center bg-white p-3 gap-4"
                >
                  <img
                    className="rounded-2xl h-[200px] md:h-full w-full object-cover"
                    src={blog.img}
                    alt=""
                    onClick={() => navigate(`/blog/${blog.blogId}`)}
                  />
                  <div className="flex flex-col gap-3 ">
                    <div className="text-lg md:text-xl font-black">
                      {blog.title}
                    </div>
                    <div className="text-sm md:text-lg font-normal">
                      {blog.text}
                    </div>
                  </div>
                  <div className="flex justify-between gap-4 items-center w-full">
                    <Link to={`/blog/${blog.blogId}`}>
                      <div className="text-lightGreen">Read More</div>
                    </Link>
                    <div>Nov 2022 | 2 mins</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetails;
