import React, { useState } from "react";
import TransferPin from "./TransferPin";
import ConfirmPin from "./ConfirmPin";

type PropType = {
  setCompleted: () => void;
};

const TransactionPinPage = ({ setCompleted }: PropType) => {
  const [page, setPage] = useState<"pin" | "confirm">("pin");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");

  const onSetPin = () => {};

  const displayPage = () => {
    switch (page) {
      case "pin":
        return (
          <TransferPin onNext={() => setPage("confirm")} setPin={setPin} />
        );
      case "confirm":
        return (
          <ConfirmPin
            pin={pin}
            setConfirmPin={setConfirmPin}
            setCompleted={setCompleted}
            onBack={() => setPage("pin")}
          />
        );

      default:
        return "";
    }
  };
  return (
    <div className=" w-full h-screen flex items-start pt-20 md:items-center justify-center">
      {displayPage()}
    </div>
  );
};

export default TransactionPinPage;
