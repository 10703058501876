import React, { useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import FormButton from "../../../components/FormComponents/FormButton/FormButton";

import Transfer from "../../../utils/apiServices/transfer";
import notify from "../../../utils/notify";
import FormSelect from "../../../components/FormComponents/FormSelect/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { setTransfer } from "../reducer";
import { IRootState } from "../../..";

type GHSPropType = {
  onVerify: SubmitHandler<FieldValues>;
  accountName: string;
  loading: boolean;
  setGetFee: SubmitHandler<FieldValues>;
  proceed: boolean;
  mobileNumber: string;
  setMobileNumber: (data: any) => void;
  setAccountName: (data: any) => void;
};

const GHS = ({
  accountName,
  setAccountName,
  loading,
  mobileNumber,
  onVerify,
  proceed,
  setGetFee,
  setMobileNumber,
}: GHSPropType) => {
  const transfer = useSelector((state: IRootState) => state.transfer.value);

  const dispatch = useDispatch();
  const [issuer, setIssuer] = useState([]);
  const [issuerLoading, setIssuerLoading] = useState(true);

  const [beneficiary, setBeneficiary] = useState(false);
  const onSetBeneficiary = (e: any) => {
    setBeneficiary(e.target.checked);
    dispatch(
      setTransfer({
        save_beneficiary: e.target.checked,
      })
    );
  };

  useEffect(() => {
    Transfer.getIssuer("GHS")
      .then((res) => {
        if (res.status) {
          setIssuer(
            res.data.map((entry: { code: string; name: string }) => ({
              label: entry.code,
              value: entry.name,
            }))
          );
          setIssuerLoading(false);
        } else {
          setIssuerLoading(false);
          notify("error", res.message);
        }
      })
      .catch(() => {
        setIssuerLoading(false);
        notify("error", "check internet connection");
      });
  }, []);
  const { register, formState, handleSubmit, control } = useForm();

  const handleInputChange = (event: any) => {
    const input = event.target.value;

    // Remove any non-digit characters from the input
    const sanitizedInput = input.replace(/\D/g, "");

    // Limit the input to a maximum of 10 digits
    const limitedInput = sanitizedInput.slice(0, 10);

    setMobileNumber(limitedInput);
  };

  const handleAccountName = (e: any) => {
    setAccountName(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit(setGetFee)}>
      <div className="text-lightGreen text-base mb-2 font-black ">
        Bank Details
      </div>
      <FormSelect
        defaultInputValue={transfer.issuer}
        placeholder={issuerLoading ? "loading..." : "Select Issuer"}
        label="Issuer"
        defaultValue={transfer.issuer}
        formState={formState}
        rules={{ required: "Select Issuer" }}
        control={control}
        name="issuer"
        options={issuer}
      />
      <div className="mt-5">
        <label
          className="text-[15px] md:text-[17px] font-medium"
          htmlFor="number"
        >
          Mobile Number
        </label>
        <div className="relative">
          <input
            // defaultValue={transfer.account_number}
            className="h-12 w-full rounded-[4px] text-[15px] md:text-base border border-gray-400 outline-none py-1 mt-2 pr-[40px] pl-5"
            type="text"
            value={mobileNumber || transfer.account_number}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="mt-5">
        <label
          defaultValue={transfer.name}
          className="text-[15px] md:text-[17px] font-medium"
          htmlFor="accountname"
        >
          Account Name
        </label>
        <div className="relative">
          <input
            className="h-12 w-full rounded-[4px] text-[15px] md:text-base border border-gray-400 outline-none py-1 mt-2 pr-[40px] pl-5"
            type="text"
            value={accountName || transfer.name}
            onChange={handleAccountName}
          />
        </div>
      </div>
      {transfer.save_beneficiary && (
        <div className="flex items-center gap-2 mt-4 text-[15px] md:text-base">
          <input
            type="checkbox"
            name=""
            id=""
            checked={beneficiary}
            onChange={onSetBeneficiary}
          />
          <div className="">Save as beneficiary</div>
        </div>
      )}
      <FormButton label="Proceed" type="big" color="green" loading={loading} />
    </form>
  );
};

export default GHS;
