import React from "react";
import Footer from "../../layout/Footer/Footer";
import Navigation from "../../layout/Navigation/Navigation";

const TermsOfUse = () => {
  return (
    <div>
      <Navigation page="Terms Of Use" />
      <div className=" bg-darkGreen">
        <div
          className="container mx-auto flex flex-col justify-center items-center text-white gap-4 md:gap-8 pt-24 pb-10 md:pb-20 px-4 md:px-0
        "
        >
          <div className="font-black text-2xl md:text-[68px]">
            Terms And Conditions
          </div>
          {/* <div className="font-medium text-xs md:text-lg leading-5 md:leading-0 text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor
            <br />
            incididunt ut labore et dolore magna aliqua.{" "}
          </div> */}
        </div>
      </div>
      <div className="container mx-auto text-[#1C1C1C] py-10 md:py-[90px] lg:py-[130px] px-4 md:px-[100px] lg:px-[250px]">
        <div className="font-black text-[18.75px] md:text-[35px] mb-6">
          Terms & Conditions
        </div>
        <div className="text-xs md:text-base">
          These terms of use are entered into by and between you and{" "}
          <span className="font-bold">VTMoney Company Limited</span>. In this
          document, the company (
          <span className="font-bold">VTMoney Company Limited</span>) and all
          the offices could be referred to as “VTMoney” “we”, “us”, “our”, as
          the case may be. The following terms and conditions govern your access
          to the use of VTMoney app or website, including any content,
          functionality and services offered on or through the platforms,
          regardless of your means of access and use (whether online or mobile,
          including by way of mobile application) (collectively the "Sites"),
          whether as a guest or a registered use. By accessing, registering with
          and using any of these services, you agree to be bound by the terms of
          the agreement and this will be treated as coming into effect on the
          date you register with us and will continue for an indefinite period,
          until terminated by you or us. Changes to terms of use We may revise
          and update these Terms of Use from time to time in our sole
          discretion. All changes are effective immediately when we post them,
          and apply to all access to and use of the Sites thereafter. Your
          continued use of the Sites following the posting of revised Terms of
          Use means that you accept and agree to the changes. You are expected
          to check this page from time to time so you are aware of any changes,
          as they are binding on you. This will always be available on
          www.vtmoneyafrica.com/terms Intellectual Property The VTMoney name,
          logo, and related trademarks are owned by{" "}
          <span className="font-bold">VTMoney Company Limited</span>, its
          licensors or other providers of such material are protected by Ghana
          and international copyright, trademark, patent, trade secret and other
          intellectual property or proprietary rights laws. All other
          trademarks, service marks, and trade names references in this site are
          the property of their respective owners. Any use, copying, or
          reproduction of the VTMoney Trademarks or Logo contained in this site,
          without prior written permission to us, is strictly prohibited. If you
          print, copy, modify, download or otherwise use or provide any other
          person with access to any part of the Site (including but not limited
          to all information, software, text, displays, images, video and audio,
          and the design, selection and arrangement thereof) in breach of the
          Terms of Use, your right to use the Sites will cease immediately and
          you must, at our option, return or destroy any copies of the materials
          you have made. No right, title or interest in or to the Sites or any
          content on the Sites is transferred to you, and all rights not
          expressly granted are reserved by the Company. Any use of the Sites
          not expressly permitted by these Terms of Use is a breach of these
          Terms of Use and may violate copyright, trademark and other laws. Use
          of Services
          <br />
          <br />
          1. When registering for and using the Portal, you must:
          <br />
          (a) Provide us with true, accurate, current and complete evidence of
          your identity, and promptly update your personal information if and
          when it changes; <br /> (b) Provide us with any identity documentation
          as may be requested by us; <br /> (c) Provide us with details of one
          or more accounts and/or Payment Instruments; and <br /> (d) Provide us
          with true, accurate, current and complete information as we indicate
          on our Website is required to use the Portal and any other information
          which may be required in relation to you, any Sender or any Payee.
          <br />
          <br />
          2. You must take reasonable steps to keep the details you use to
          access the Portal safe and to prevent their fraudulent use. These
          steps include:
          <br />
          (a) Disguising those details if you write them down and keeping them
          out of sight of third parties who should not have access to the
          Account.
          <br /> (b) Not sharing those details with anyone else, unless they are
          a Third Party Provider that you have authorized to give Payment
          Instructions on your behalf and/or to access your Account, and they
          need those details in order to provide these services to you;
          <br />
          <br />
          3. You must only use the Portal to transact with third parties you do
          know and trust. If you choose to pay third parties for goods and
          services using our Services, please note that we have no control over,
          and is not responsible for, the quality, safety, legality, or delivery
          of such goods or services, and that any such use of the Portal and our
          Services is entirely at your own risk.
          <br />
          <br />
          4. You must only act on your own behalf. You may not submit an
          Instruction or receive a Transaction on behalf of a third person.
          <br />
          <br />
          5. When transacting on our Website or when interacting with us, with
          another user or with a third party, you must do the following:
          <br /> (a) Comply with the terms of your Agreement with us as well as
          any applicable laws, rules or regulations;
          <br /> (b) Provide confirmation of any information you provide to us,
          including proof of identity;
          <br /> (c) Co-operate in any investigation that we reasonably carry
          out, or that is carried out by any law enforcement agency, government
          agency or regulatory authority; <br /> (d) Not provide false,
          inaccurate, or misleading information; <br /> (e) Not use an
          anonymising proxy (a tool that attempts to make activity untraceable);
          and <br /> (f) Not copy or monitor our Portal or Website using any
          robot, spider, or other automatic device, or manual process, without
          our prior written permission.
          <br />
          <br />
          6. If you have any problems exchanging money, using our virtual card
          or our Website, you should contact us without delay through the
          channels listed at the end of these Terms and Conditions.
          <br />
          <br />
          7. If you suspect or become aware that the details that you use to
          access your Virtual Card or your Account, or that money in the
          Account, has been lost, stolen, compromised, used without your
          authorization, or used fraudulently, you must contact us immediately
          through the channels listed at the end of these Terms and Conditions.
          <br />
          <br />
          8. Provided it would not be unlawful for us to do so, and it would not
          compromise reasonable security measures, we will contact you by phone
          or email if there is an actual or suspected fraud affecting your use
          of the Portal, any money we hold for your account or a security threat
          affecting the Portal, your money or your Virtual Card Account.
          Prohibited Use You may use the Sites only for lawful purposes and in
          accordance with these Terms of Use. Without limitation, you agree not
          to use the Site: <br />
          <br />
          1. For the purpose of exploiting, harming or attempting to exploit or
          harm minors in any way by exposing them to inappropriate content,
          asking for personally identifiable information or otherwise. <br />
          <br />
          2. To send Transaction Amounts in connection with illegal activity,
          including but not limited to money-laundering, fraud and the funding
          of terrorist organizations. If we reasonably believe you are using the
          Portal or our Services in connection with illegal activity or for any
          fraudulent purpose, or are permitting a third party to do so, we may
          report you to the appropriate legal authorities. <br />
          <br />
          3. To impersonate or attempt to impersonate the Company, a Company
          employee, another user or any other person or entity (including,
          without limitation, by using e-mail addresses or screen names
          associated with any of the foregoing). <br />
          <br />
          4. To engage in any other conduct that restricts or inhibits anyone's
          use or enjoyment of the Sites, or which, as determined by us, may harm
          the Company or users of the Sites or expose them to liability. DO NOT{" "}
          <br />• Use the Sites in any manner that could disable, overburden,
          damage, or impair the Site or interfere with any other party's use of
          the Sites, including their ability to engage in real time activities
          through the Sites. <br />• Use any robot, spider or other automatic
          device, process or means to access the Sites for any purpose,
          including monitoring or copying any of the material on the Sites.
          <br />• Use any manual process to monitor or copy any of the material
          on the Sites or for any other unauthorized purpose without our prior
          written consent. <br />• Use any device, software or routine that
          interferes with the proper working of the Sites.
          <br />• Introduce any viruses, Trojan horses, worms, logic bombs or
          other material which is malicious or technologically disruptive or
          harmful.
          <br />• Attempt to gain unauthorized access to, interfere with, damage
          or disrupt any parts of the Sites, the server(s) on which the Sites
          is/are stored, or any server, computer or database connected to the
          Sites. <br />• Attack the Sites via a denial-of-service attack or a
          distributed denial-of-service attack. <br />• Otherwise attempt to
          interfere with the proper working of the Sites. <br />
          <br />
          Warranty Disclaimer WE WORK CONSISTENTLY TO KEEP VTMoney AVAILABLE AT
          ALL TIMES FOR YOU. HOWEVER, YOU USE OR ACCESS VTMoney AT YOUR OWN
          RISK. AS PERMITED BY LAW, VTMoney, OUR SERVICE PROVIDERS, OUR
          LICENSORS, AND OR ALL OUR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES OR
          AGENTS (COLLECTIVELY THE “VTMoney PARTIES”) EXPRESSLY DISCLAIM ALL
          WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY,
          INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
          NON-INFRINGEMENT, RELATED TO OUR SITES. YOU UNDERSTAND AND AGREE THAT
          YOUR USE OF OUR SITES IS AT YOUR OWN RISK. VTMoney PARTIES ARE NOT
          RESPONSIBLE FOR THE TIMELINESS OF DELIVERY OF CONTENT, ANY FAILURES OF
          DELIVERY, OR ANY LOSS OR DAMAGE OF ANY KIND YOU CLAIM WAS INCURRED AS
          A RESULT OF THE USE OF OUR WEBSITE UNDER NO CIRCUMSTANCES, WILL ANY OF
          VTMoney PARTIES BE LIABLE TO YOU OR TO ANY PERSON OR ENTITY CLAIMING
          THROUGH YOU FOR ANY LOSS, INJURY, LIABILITY OR DAMAGES ARISING OUT OF
          OR IN CONNECTION WITH YOUR ACCESS TO, USE OF, INABILITY TO USE, OR
          RELIANCE ON ANY OF OUR SITES OR ANY CONTENT, PRODUCT, FEATURE OR
          SERVICE PROVIDED TO YOU THROUGH OR IN CONNECTION WITH ANY OF OUR
          SITES. VTMoney MAKES NO WARRANTY THAT OUR SERVICES OR PRODUCTS WILL
          MEET YOUR EXPECTATIONS OR REQUIREMENTS OR THAT OUR SERVICES, PRODUCTS,
          WEBSITE, OR APP WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE
          Denial or Delay Processing We may refuse any attempted payment to you
          or on your Virtual Card, refuse to process any Instruction, or delay
          processing any such payment or Instruction, at any time for any of the
          reasons set out below:
          <br /> (a) We are not satisfied that we have your consent;
          <br /> (b) We reasonably believe that you, a Sender or Payee are using
          (or are allowing someone else to use) our Portal, our Website or our
          Services, in breach of your (or their) Agreement with us or any
          applicable laws, rules or regulations, or in furtherance of illegal,
          fraudulent or Prohibited activities;
          <br /> (c) We have reason to believe that processing any such payment
          or Instruction would violate anti-money laundering or counterterrorism
          financing laws, rules and regulations;
          <br /> (d) In the case of a payment to us for your account, or to your
          Virtual Card, we have reason to believe the security of the account or
          Payment Instrument used to make the payment has been compromised or we
          suspect the unauthorized or fraudulent use of that account or Payment
          Instrument;
          <br /> (e) We reasonably believe that you are using our Services to
          purchase goods or services from third parties you do not know or
          trust;
          <br /> (f) We are unable to verify your identity, or the identity of
          the Sender or the Payee (as the case may be).
          <br /> (g) You do not provide us with any information we have
          reasonably requested from you, as explained earlier;
          <br /> (h) We reasonably believe there may be fraudulent activity or
          other financial crime affecting you, any Sender or Payee, any money we
          hold in your account, your Virtual Card, or any payment;
          <br /> (i) We are obliged to do so by any law, regulation, court,
          regulatory or government body;
          <br /> (j) There is a dispute (which we reasonably believe is genuine)
          about who owns, or is entitled to, any money held by us to your
          account, or on your Virtual Card. This includes (but is not limited
          to) the situation where a Sender makes a request for a refund of funds
          the Sender has sent to us for your account or to your Virtual Card, or
          we are made aware that the Sender has made a claim against you for the
          return of those funds;
          <br /> (k) You have broken the terms and conditions of your Agreement
          with us in a way that we reasonably believe justifies us in refusing
          or delaying processing any payment or Instruction, and you have not
          put this right;
          <br /> (l) We reasonably believe that processing the payment or
          Instruction would breach the terms and conditions of your Agreement
          with us;
          <br /> (m) In the case of a Money Transfer or a payment into or out of
          Virtual Card, the amount of the payment exceeds any transaction limit.
          <br /> (n) In the case of a Money Transfer or a payment out of the
          Account/Virtual Card, there are insufficient funds to make the payment
          and/or to cover our Service Fee and any other fees, costs or charges
          we may incur in making the payment;
          <br /> (o) In the case of a Money Transfer or a payment out of the
          Virtual Card Account, the Payee has not yet set up their account to
          receive payment, or has not registered with us to use our services, or
          the terms of the Payee's account prevent completion of the
          Transaction, or the Payee's account is unable to receive payment in
          the form in which we make payments;
          <br /> (p) You are subject to an order relating to your bankruptcy, or
          you have entered into a voluntary agreement with your creditors; or
          <br /> (q) We have blocked your (or a Third Party Provider's) use of
          the Portal and/or your Virtual Card Account under clause ‘h’. Making
          Payments
          <br />
          <br />
          1. For each Payment Instruction you give us, you must pay us the
          applicable Service Fee (this will be explained in the Portal). Payment
          becomes due at the time that you submit your Payment Instruction.
          <br />
          <br />
          2. When you give us a Payment Instruction, you are requesting that we
          process the Transaction on your behalf and you are consenting to the
          execution of the Transaction. Your Payment Instruction will be treated
          as received by us once you submit it to us using the Portal.
          <br />
          <br />
          3. It is your responsibility to make sure all the details are accurate
          before you give us a Payment Instruction. Once we have received a
          Payment Instruction, it is not normally possible to change any details
          of that Payment Instruction. You will be given the opportunity to
          confirm a Payment Instruction before submission and you must check the
          details carefully.
          <br />
          <br />
          4. Once we have received your Instruction, you cannot cancel it.
          <br />
          <br />
          5. The total amount (the Transaction Amount, Service Fee and other
          applicable fees and charges) that you will pay and the relevant
          exchange rate selected by us will be displayed clearly in the Portal
          before you are asked to confirm your Transaction.
          <br />
          <br />
          6. If a Payee’s account is denominated in a currency other than the
          currency you instructed us to make payment in there may be delays,
          additional charges or different exchange rates.
          <br />
          <br />
          7. We will have no responsibility for any fees or charges you may
          incur from the use of a particular account or Payment Instrument to
          fund a Transaction. These may include, but are not limited to
          unauthorized overdraft fees imposed by banks if there are insufficient
          funds in your bank account, or "cash advance" fees and additional
          interest which may be imposed by credit card providers if they treat
          use of our Services as a cash transaction rather than a purchase
          transaction.
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUse;
