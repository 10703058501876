import notFound from "../../../assests/illustrations/notFound.png";

const NotFound = () => {
  return (
    <div className="flex justify-center w-full">
      <img className="w-[80%] md:w-[500px] mt-[50px]" src={notFound} alt="" />
    </div>
  );
};

export default NotFound;
