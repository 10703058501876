import { useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../components/FormComponents/FormInput/FormInput";
import FormSelect from "../../../components/FormComponents/FormSelect/FormSelect";
import FormButton from "../../../components/FormComponents/FormButton/FormButton";
import { useSelector } from "react-redux";
import { IRootState } from "../../..";

type ResolveDataPropType = {
  mobileNetwork: any;
  onProceed: SubmitHandler<FieldValues>;
  loading: boolean;
  netWorkLoading: boolean;
  onSetBiller: (biller: string) => void;
};

const ResolveData = ({
  netWorkLoading,
  onSetBiller,
  mobileNetwork,
  onProceed,
  loading,
}: ResolveDataPropType) => {
  const bill = useSelector((state: IRootState) => state.bill.value);

  const [networks, setNetworks] = useState([]);
  const [biller, setBiller] = useState(null);
  const [amount, setAmount] = useState("");

  const { register, formState, handleSubmit, control } = useForm();

  useEffect(() => {
    setNetworks(
      mobileNetwork.map(
        (entry: {
          name: string;
          item_code: string;
          biller_code: string;
          biller_name: string;
          amount: number;
        }) => ({
          label: entry.name,
          value: `${entry.biller_code} ${entry.item_code} ${entry.biller_name} ${entry.amount}`,
        })
      )
    );
  }, [mobileNetwork]);

  const handleProviderChange = (val: any) => {
    setBiller(val);
    onSetBiller(val);
  };

  const handlePackageChange = (val: any) => {
    const selectedValue = val.value.split(" ");
    setAmount(selectedValue[selectedValue.length - 1]);
  };

  return (
    <div>
      <div className="font-bold text-xl md:text-2xl py-4">Purchase Data</div>
      <div className="text-gray-400 font-thin">
        Fill the fields below to purchase from any network
      </div>
      <form onSubmit={handleSubmit(onProceed)}>
        <FormInput
          formState={formState}
          label="Customer ID / Number"
          defaultValue={bill.customer.toString()}
          name="number"
          register={register}
          rules={{ required: "Enter number" }}
          type="number"
          placeholder="Enter mobile number"
        />
        <FormSelect
          placeholder={"Select network"}
          label="Network Provider"
          formState={formState}
          rules={{ required: "Select provider" }}
          control={control}
          name="network"
          options={[
            { label: "MTN", value: "MTN" },
            { label: "Airtel", value: "Airtel" },
            { label: "Glo", value: "Glo" },
            { label: "9Mobile", value: "9Mobile" },
          ]}
          onChange={(e) => handleProviderChange(e.value)}
        />
        {biller && (
          <>
            <FormSelect
              placeholder={netWorkLoading ? "loading" : "Select Mobile Network"}
              label="Mobile Network"
              formState={formState}
              rules={{ required: "Mobile network is required" }}
              control={control}
              name="account"
              options={networks}
              onChange={(e) => handlePackageChange(e)}
            />
          </>
        )}
        {biller && !netWorkLoading && (
          <FormInput
            defaultValue={amount}
            formState={formState}
            label="Amount"
            name="amount"
            register={register}
            rules={{}}
            type="number"
            placeholder="1000"
            disabled
          />
        )}

        <FormButton
          loading={loading}
          color="green"
          label={"Proceed"}
          type="big"
        />
      </form>
    </div>
  );
};

export default ResolveData;
