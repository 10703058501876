/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { KYCPropType } from "./types";

const initialStateValue: KYCPropType = {
  address: "",
  business_name: "",
  bvn: 0,
  city: "",
  country: "",
  dob: "",
  document_number: 0,
  document_type_id: 0,
  firstname: "",
  lastname: "",
  middlename: "",
  postal_code: "",
  registration_number: "",
  state: "",
  tin: "",
};

export const kycSlice = createSlice({
  name: "kyc",
  initialState: { value: initialStateValue },
  reducers: {
    setKyc: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
    resetKyc: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setKyc, resetKyc } = kycSlice.actions;

export default kycSlice.reducer;
